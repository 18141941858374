import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { Button, IcoSearch } from "llane-ui";
import { useTranslate } from "../contexts/SettingContext";

const PostBox = styled.div`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  border-radius: 16px;
  margin: 0 auto 0 auto;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10.5pt;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-self: flex-end;
  width: 100%;
  max-width: 500px;
  min-width: 250px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  display: flex;
  justify-content: flex-start;
  width: auto;
  margin-bottom: 0;
  margin-right: 8px;
  flex-grow: 1;
  background: transparent;
  border: none;
  color: rgba(150, 150, 150, 1);
  outline: none;
  font-size: 10pt;
  resize: none;
  padding: 0 0 0 0.7rem;
  overflow-y: hidden; // Prevent vertical overflow

  @media (max-width: 769px) {
    width: 100%;
  }
`;

const StyledSocialButton = styled(Button)`
  margin-top: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 0;
  padding: 0;

  &:disabled {
    background: rgba(40, 40, 40, 0.1);
  }
`;

const Search: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [textInput, setTextInput] = useState(searchParams.get("search") ?? "");
  const { t } = useTranslate();

  const searchHandler = useCallback(() => {
    if (textInput.trim()) {
      searchParams.set("search", textInput.trim());
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, textInput]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        searchHandler();
      }
    },
    [searchHandler]
  );

  return (
    <PostBox>
      <TextArea
        placeholder={t("LabelSearch")}
        maxLength={365}
        rows={1}
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        onKeyDown={handleKeyPress}
      />
      <StyledSocialButton onClick={searchHandler} disabled={!textInput.trim()}>
        <IcoSearch size="14px" style={{ margin: 0 }} />
      </StyledSocialButton>
    </PostBox>
  );
};

export default Search;
