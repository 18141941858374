import { useState } from "react";
import { ChatMessage } from "../types/prompt";

export const useProviderPrompt = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [currentModel, setCurrentModel] = useState("Arcadia");
  const [promptId, setPromptId] = useState<number | undefined>();

  return {
    messages,
    setMessages,
    currentModel,
    setCurrentModel,
    promptId,
    setPromptId
  };
};
