import { useCallback, useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";
import { fetchCustomCommandByUserId } from "../api/customCommandApiCall";
import { CustomCommand } from "../types/customCommand";

export const useCustomCommands = () => {
  const [customCommands, setCustomCommands] = useState<
    CustomCommand[] | undefined
  >();
  const { user, accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    if (user && user.user_id && accessToken) {
      fetchCustomCommandByUserId(user.user_id, accessToken)
        .then((data) => {
          if (data && Array.isArray(data)) {
            setCustomCommands(data);
          } else {
            setCustomCommands([]);
          }
        })
        .catch(() => {
          setCustomCommands([]);
        });
    }
  }, [user, setCustomCommands, accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { customCommands, triggerFetch };
};
