import { useState } from "react";
import {
  Box,
  Button,
  FlexContainer,
  HeadingLarge,
  InputOutlined,
  PageWrapper,
  Spacer,
  Text
} from "llane-ui";
import { useTranslate } from "../../contexts/SettingContext";
import { Link } from "react-router-dom";

function Signup() {
  const { t } = useTranslate();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
    }
  };

  return (
    <>
      <PageWrapper>
        <Spacer gap="10%" />
        <FlexContainer
          breakDirection="row"
          alignHorizontal="center"
          alignVertical="center"
          onKeyDown={_handleKeyDown}
        >
          <Box padding="50px 50px" alignText="center">
            <HeadingLarge>{t("LabelCreateAnAccount")}</HeadingLarge>
            <Text>{t("LabelEnterDetails")}</Text>
            <Spacer />
            <InputOutlined
              minWidth="300px"
              placeholder={t("LabelFirstName")}
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
            />
            <InputOutlined
              minWidth="300px"
              placeholder={t("LabelLastName")}
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
            />
            <InputOutlined
              minWidth="300px"
              placeholder={t("LabelEmail")}
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <InputOutlined
              minWidth="300px"
              placeholder={t("LabelPassword")}
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
            <Button width="100%">{t("LabelCreateAccount")}</Button>
            <FlexContainer breakDirection="row" gap="20px">
              <Text>Already signed up?</Text>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Text>Login</Text>
              </Link>
            </FlexContainer>
          </Box>
        </FlexContainer>
      </PageWrapper>
    </>
  );
}

export default Signup;
