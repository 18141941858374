import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useSearch = () => {
  const [searchParams] = useSearchParams();

  const search = useMemo(() => {
    const _search = searchParams.get("search");
    if (_search && _search !== "") {
      return _search;
    } else return null;
  }, [searchParams]);

  return search;
};
