import { useState } from "react";
import { userLogin } from "../../api/userApiCall";
import { useUser } from "../../contexts/UserContext";
import { Link, Navigate } from "react-router-dom";
import {
  Box,
  Button,
  FlexContainer,
  HeadingLarge,
  InputOutlined,
  PageWrapper,
  Spacer,
  Text
} from "llane-ui";
import { useTranslate } from "../../contexts/SettingContext";
import { toast } from "react-toastify";

export function Login() {
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const { setAccessToken, user, loginType, setLoginType } = useUser();

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    if (email && password) {
      setLoading(true);
      userLogin({ email, password })
        .then((response) => {
          toast.success("Successfully signed in.");
          const { token } = response;
          if (token) setAccessToken(token);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <PageWrapper>
        <Spacer gap="10%" />
        <FlexContainer
          breakDirection="row"
          alignHorizontal="center"
          alignVertical="center"
          onKeyDown={_handleKeyDown}
        >
          <Box padding="50px 50px" alignText="center">
            <HeadingLarge>{t("LabelLogin")}</HeadingLarge>
            <InputOutlined
              value={email}
              name="email"
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder={t("LabelEmail")}
            />
            <InputOutlined
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              placeholder={t("LabelPassword")}
              name="password"
              type="password"
            />
            <div style={{ textAlign: "start", margin: 10 }}>
              <input
                type="checkbox"
                value={loginType}
                onChange={(e: any) => {
                  setLoginType(e.target.value);
                }}
              />{" "}
              Remember me
            </div>
            <Button width="100%" onClick={() => handleLogin()}>
              {loading ? `${t("LabelSubmitting")}` : `${t("LabelLogin")}`}
            </Button>
            <FlexContainer breakDirection="row" gap="20px">
              <Text>Create new user?</Text>
              <Link to="/signup" style={{ textDecoration: "none" }}>
                <Text>Sign up</Text>
              </Link>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Spacer gap="10%" />
      </PageWrapper>
    </>
  );
}

export default Login;
