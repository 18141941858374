import { FlexContainer, Text } from "llane-ui";

const TeamManagementSettings = () => {

  return (
    <FlexContainer breakDirection="column" width="100%">
      <Text>Team Management</Text>
    </FlexContainer>
  );
};

export default TeamManagementSettings;
