import { deleteApiCall, getApiCall, postApiCall } from ".";
import { FineTuneForApi } from "../types/fineTune";

export const fetchFineTuneByUserId = (user_id: number, token = "") => {
  const url = `/fine-tuning/fetch/user/${user_id}`;
  return getApiCall(url, token);
};

export const fetchFineTuningById = (fine_tuning_id: number, token = "") => {
  const url = `/fine-tuning/fetch/${fine_tuning_id}`;
  return getApiCall(url, token);
};

export const saveFineTune = (fine_tune: FineTuneForApi, token = "") => {
  const url = `/fine-tuning/new`;
  return postApiCall(url, fine_tune, token);
};

export const updateFineTune = (fine_tune: FineTuneForApi, token = "") => {
  const url = `/fine-tuning/update`;
  return postApiCall(url, fine_tune, token);
};

export const removeFineTune = (fine_tuning_id: number, token = "") => {
  const url = `/fine-tuning/${fine_tuning_id}`;
  return deleteApiCall(url, token);
};
