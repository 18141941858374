import useSuggestions from "../../hooks/useSuggestions";
import { FlexContainer, Heading, Spacer, Text, TextDimmed } from "llane-ui";
import { SuggestionContainer, SuggestionItem } from "./styled";
import ArcadiaLogo from "../../assets/images/logo_64.png";
import { useTranslate } from "../../contexts/SettingContext";

const Suggestions = ({ sendMessage }: any) => {
  const suggestions = useSuggestions();
  const { t } = useTranslate();

  const handleClick = (suggestion: any) => {
    const prompt = suggestion.prompt;
    sendMessage(prompt);
  };

  return (
    <>
      <FlexContainer
        margin="4rem 0"
        alignText="center"
        alignHorizontal="center"
        alignVertical="center"
      >
        <img src={ArcadiaLogo} alt="logo" width={48} height={48} />
        <Spacer gap="10px" />
        <Heading>{t("LabelHello")}</Heading>
      </FlexContainer>
      <SuggestionContainer>
        {suggestions?.map((suggestion: any, index: any) => (
          <SuggestionItem
            key={suggestion.id}
            onClick={() => handleClick(suggestion)}
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            <Text>
              <b>{suggestion.title}</b>
            </Text>
            <TextDimmed>{suggestion.description}</TextDimmed>
          </SuggestionItem>
        ))}
      </SuggestionContainer>
    </>
  );
};

export default Suggestions;
