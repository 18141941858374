import { useEffect, useRef, useState } from "react";
import { ToolBar } from "../components/ToolBar";
import {
  ContextFactor,
  CommandFailSentence,
  ImageStorage,
  AIEngineEndpoints
} from "../constants";
import textFormatter from "../utils/textFormatter";
import {
  DefaultBorderRadius,
  Dot,
  DotsLoader,
  FormattedResponse,
  FormattedResponseContainer,
  LoaderPaddingWrapper,
  MessageBubble,
  PageWrapperMargin,
  ProcessingJsxWrapper,
  PromptButton,
  PromptTextBorder,
  ResponseContainer
} from "../theme";
import {
  FlexContainer,
  IcoSend,
  IcoSquare,
  PageWrapper,
  ResponsiveBox,
  TextArea
} from "llane-ui";
import { usePrompt } from "../contexts/PromptContext";
import { useSearchParams } from "react-router-dom";
import { fetchPromptById } from "../api/promptApiCall";
import { useUser } from "../contexts/UserContext";
import { LoadingState } from "../types";
import { useTranslate } from "../contexts/SettingContext";
import { sendMessageToAi, sendMessageToAiWithStream } from "../api/aiApiCall";
import { defaultCommands } from "../utils/defaultCommands";
import { DefaultBoxShadow } from "llane-ui";
import Suggestions from "../components/Suggestions";
import { ChatMessage } from "../types/prompt";
import { makeUnique } from "../utils/random";

const isMobileOrTablet = () => window.innerWidth <= 769;

export function Prompt() {
  const { t } = useTranslate();
  const { accessToken } = useUser();
  const { messages, setMessages, currentModel, promptId, customCommands } =
    usePrompt();
  const [loading, setLoading] = useState<LoadingState>(LoadingState.Idle);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [summaryResponse, setSummaryResponse] = useState("");
  const [userResponse, setUserResponse] = useState<string>("");
  const [trainingData /*setTrainingData*/] = useState<string>("{}");
  const [isChatEnabled /*setIsChatEnabled*/] = useState<boolean>(true);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState<boolean>(false);

  const responseContainerRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<HTMLTextAreaElement>(null);

  const abortController = useRef<AbortController | undefined>();

  const sendMessage = async (message: string) => {
    if (!message.trim()) return;
    setIsAwaitingResponse(true); // Start loading state

    const userMessage = message.trim();
    setUserResponse(""); // Clear the input immediately

    const controller = new AbortController();

    abortController.current = controller;

    const signal = controller.signal;

    if (userMessage?.[0] === "/") {
      const firstWord = userMessage.split(" ")?.[0];

      const defaultCommand = defaultCommands.find((item) => {
        if (item?.command && `/${item.command}` === firstWord) return true;
        else return false;
      });

      setMessages((prev) => [
        ...prev,
        { subject: "User", content: userMessage, type: "text" }
      ]);

      if (defaultCommand && Boolean(defaultCommand.callback)) {
        try {
          const argument = userMessage.slice(userMessage.indexOf(" ") + 1);
          const data = await defaultCommand.callback(
            argument,
            accessToken,
            signal
          );

          setMessages((prev) => [...prev, data]);
        } catch {
          setMessages((prev) => [
            ...prev,
            {
              subject: "AI",
              content: "Something went wrong. Please try again.",
              type: "text"
            }
          ]);
        }
      } else {
        const command = customCommands?.find((item) => {
          if (item?.command && `/${item.command}` === firstWord) return true;
          else return false;
        });

        if (command && command.command_return) {
          setMessages((prev) => [
            ...prev,
            { subject: "AI", content: command.command_return, type: "text" }
          ]);
        } else {
          setMessages((prev) => [
            ...prev,
            { subject: "AI", content: CommandFailSentence, type: "text" }
          ]);
        }
      }
      setIsAwaitingResponse(false);
      return;
    }

    // Update UI immediately with user message
    setMessages((prev) => [
      ...prev,
      { subject: "User", content: userMessage, type: "text" }
    ]); // Ensure this matches your ChatMessage type

    // Calculate the start index for slicing the messages array based on ContextFactor
    const startIndex = Math.max(messages.length - parseInt(ContextFactor), 0);
    // Extract the last 'ContextFactor' number of messages for context
    const contextMessages = messages.slice(startIndex).map((message) => ({
      role: message.subject.toLowerCase(),
      content:
        message.type === "photo"
          ? "AI_Image"
          : message.type === "video"
          ? "AI_Video"
          : message.content,
      type: message.type // Ensure this is passed along, assuming your context includes type
    }));

    try {
      const payload = {
        content: userMessage,
        trainingData: trainingData.trim(),
        context: contextMessages
      };

      if ((AIEngineEndpoints as any)?.[currentModel]?.extra) {
        const uuid = makeUnique("prompt-");

        const callback = (message: string) => {
          setMessages((prev: ChatMessage[]) => {
            const item = prev.find(({ key }) => key === uuid);
            if (item) {
              const messages = prev.map((item) => {
                if (item.key === uuid && item.content !== message) {
                  return {
                    ...item,
                    content: message
                  };
                } else {
                  return item;
                }
              });
              return [...messages];
            } else {
              return [
                ...prev,
                { key: uuid, subject: "AI", content: message, type: "text" }
              ];
            }
          }); // Ensure AI response also matches ChatMessage type
        };

        await sendMessageToAiWithStream(
          currentModel,
          payload,
          callback,
          accessToken,
          signal
        ).catch(() => {
          if (signal.aborted) {
            console.log("stopped");
          }
        });
      } else {
        const response = await sendMessageToAi(
          currentModel,
          payload,
          accessToken,
          signal
        );

        const aiTextResponse = response.choices[0].message.content;
        // Update UI with AI response
        setMessages((prev) => [
          ...prev,
          { subject: "AI", content: aiTextResponse, type: "text" }
        ]);
      }
    } catch (error) {
      console.error("Error sending message to API:", error);
    } finally {
      setIsAwaitingResponse(false); // End loading state
    }
  };

  const handleStop = () => {
    if (abortController.current) {
      abortController.current.abort();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const textarea = chatInputRef.current;
      if (textarea) {
        const lineHeight = parseInt(
          window.getComputedStyle(textarea).lineHeight,
          10
        );
        const defaultHeight = lineHeight * 2; // Initial height for empty or minimal text
        const maxHeight = lineHeight * 15; // Max height for 15 lines

        textarea.style.height = "auto"; // Reset the height to get accurate scrollHeight
        const desiredHeight =
          textarea.scrollHeight > defaultHeight
            ? Math.min(textarea.scrollHeight, maxHeight)
            : defaultHeight;
        if (textarea.scrollHeight > textarea.clientHeight) {
          textarea.style.height = `${desiredHeight}px`;
        } else {
          textarea.style.height = `${defaultHeight}px`; // Reset to default height if not overflowing
        }
      }
    };

    const textarea = chatInputRef.current;
    if (textarea) {
      textarea.addEventListener("input", handleResize);
      handleResize(); // Initial resize adjustment
    }

    // Re-adjust when userResponse changes
    handleResize();

    return () => {
      if (textarea) {
        textarea.removeEventListener("input", handleResize);
      }
    };
  }, [userResponse]);

  useEffect(() => {
    // Automatically scroll to the bottom of the response container when messages change
    const scroll = responseContainerRef.current;
    if (scroll) {
        setTimeout(() => {
            scroll.scrollTop = scroll.scrollHeight;
        }, 100); // Adjust timeout as necessary to ensure it works across different devices and conditions
    }
    // Ensure focus is reset to chat input after each new message ONLY for desktops
    if (isChatEnabled && !isMobileOrTablet()) {
        chatInputRef.current?.focus();
    }
}, [messages, isChatEnabled]);

  useEffect(() => {
    // Ensure focus is reset to chat input after each new message and after response finishes loading
    if (isChatEnabled && !isMobileOrTablet()) {
        chatInputRef.current?.focus();
    }
}, [messages, isChatEnabled, isAwaitingResponse]);

  useEffect(() => {
    const prompt_id = searchParams.get("prompt_id");
    if (accessToken && setMessages) {
      if (!prompt_id || prompt_id === "") {
        setLoading(LoadingState.Loaded);
      } else if (loading === LoadingState.Idle) {
        // fetch from backend
        fetchPromptById(Number(prompt_id), accessToken)
          .then((res) => {
            if (res && res.prompt) {
              const messages = JSON.parse(res.prompt);
              if (Array.isArray(messages)) {
                setMessages(messages);
              }
            }
          })
          .finally(() => {
            setLoading(LoadingState.Loaded);
          });
      }
    }
  }, [loading, searchParams, accessToken, setMessages]);

  useEffect(() => {
    if (promptId) {
      setSearchParams({ prompt_id: promptId.toString() });
    } else {
      searchParams.delete("prompt_id");
      setSearchParams(searchParams);
    }
  }, [promptId, searchParams, setSearchParams]);

  return (
    <>
      <PageWrapper margin={PageWrapperMargin} height={"100vh"}>
        <ToolBar messages={messages} setUserInput={setUserResponse} />
        <ResponsiveBox
          alignVertical="bottom"
          borderRadius="0 0 30px 30px"
          style={{ flex: 1, overflow: "hidden" }}
        >
          <FlexContainer
            height="100%"
            minHeight="20vh"
            breakDirection="column-reverse"
            style={{ flex: 1, overflowY: "auto" }}
          >
            <ResponseContainer ref={responseContainerRef}>
              {loading === LoadingState.Loading ||
              loading === LoadingState.Idle ? (
                <LoaderPaddingWrapper>
                  <DotsLoader>
                    <Dot delay="0s" />
                    <Dot delay="0.1s" />
                    <Dot delay="0.2s" />
                  </DotsLoader>
                </LoaderPaddingWrapper>
              ) : (
                <>
                  {(!messages || messages.length === 0) && (
                    <Suggestions sendMessage={sendMessage} />
                  )}
                  {messages.map((msg, index) => (
                    <FormattedResponseContainer
                      key={index}
                      subject={msg.subject}
                    >
                      {msg.type === "photo" ? (
                        <img
                          src={`${ImageStorage}/${msg.content}`}
                          alt="Generated Content"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            boxShadow: DefaultBoxShadow,
                            borderRadius: "0px 30px 30px 30px"
                          }}
                        />
                      ) : (
                        <MessageBubble subject={msg.subject}>
                          <FormattedResponse>
                            {textFormatter(msg.content)}
                          </FormattedResponse>
                        </MessageBubble>
                      )}
                    </FormattedResponseContainer>
                  ))}
                </>
              )}
            </ResponseContainer>
          </FlexContainer>
          <FlexContainer
            background="transparent"
            borderThickness="1px"
            borderColor={PromptTextBorder}
            borderRadius={DefaultBorderRadius}
            breakDirection="row"
            padding="1rem"
            gap="1rem"
            height="auto"
          >
            {isAwaitingResponse ? (
              <ProcessingJsxWrapper>
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              </ProcessingJsxWrapper>
            ) : (
              <TextArea
                ref={chatInputRef}
                background="transparent"
                placeholder={t("LabelTextAreaPlaceholder")}
                value={userResponse}
                width="100%"
                resize="none"
                borderRadius="0"
                style={{
                  lineHeight: "1.5",
                  overflow: "auto",
                  padding: "0"
                }}
                rows={1}
                onChange={(e) => {
                  setUserResponse(e.target.value);
                }}
                onKeyDown={(e) => {
                  // Check for Enter without Shift
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent the default action (newline) only for Enter alone
                    if (!isAwaitingResponse && userResponse.trim().length > 0) {
                      sendMessage(userResponse);
                    }
                  }
                }}
                disabled={isAwaitingResponse}
              />
            )}
            <PromptButton
              onClick={() => {
                if (isAwaitingResponse) {
                  handleStop();
                } else {
                  sendMessage(userResponse);
                }
              }}
              disabled={!isAwaitingResponse && userResponse.trim().length === 0}
            >
              {isAwaitingResponse ? (
                <ProcessingJsxWrapper>
                  <IcoSquare size="20px" margin="0" />
                </ProcessingJsxWrapper>
              ) : (
                <ProcessingJsxWrapper>
                  <IcoSend size="20px" margin="0 2px -3px 0" />
                </ProcessingJsxWrapper>
              )}
            </PromptButton>
          </FlexContainer>
        </ResponsiveBox>
      </PageWrapper>
    </>
  );
}

export default Prompt;
