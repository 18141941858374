import { useEffect, useMemo, useState } from "react";
import { fetchSuggestions } from "../api/aiApiCall";
import { useUser } from "../contexts/UserContext";
import { useUserSetting } from "../contexts/SettingContext";

const useSuggestions = () => {
  const [suggestions, setSuggestions] = useState<any>();
  const { accessToken } = useUser();
  const { setting } = useUserSetting();

  const language = useMemo(() => {
    if (setting?.language) return setting?.language as string;
    else return "en-uk";
  }, [setting]);

  useEffect(() => {
    if (accessToken) {
      fetchSuggestions(4, language, accessToken)
        .then((items: any) => {
          setSuggestions(items);
        })
        .catch(() => {});
    }
  }, [accessToken, language]);

  return suggestions;
};

export default useSuggestions;
