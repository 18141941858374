import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoCloud,
  IcoFolder,
  IcoX,
  LinkWrapper,
  Modal,
  Text
} from "llane-ui";
import { DefaultBorderRadius, DefaultBoxGap } from "../../../theme";
import { useModal } from "../../../contexts/ModalContext";
import React, { useCallback, useRef } from "react";
import { handleLoadPrompt } from "../../../utils/loadFromFile";
import { usePrompt } from "../../../contexts/PromptContext";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../../contexts/SettingContext";

const OpenPromptModal = () => {
  const navigate = useNavigate();
  const { isOpen, handleClose } = useModal("openPrompt");
  const { t } = useTranslate();

  const { setMessages } = usePrompt();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const triggerFileInput = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      fileInputRef.current?.click();
      handleClose();
    },
    [fileInputRef, handleClose]
  );

  const handleOpenPromptFunc = (event: any) => {
    handleLoadPrompt(event, (messages: any) => {
      setMessages(messages);
      navigate("/prompt");
    });
  };

  const handleOpenFromCloud = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      handleClose(); // Close the modal first
      if (window.location.pathname === "/saved-prompts") {
        window.location.reload(); // Force reload the page
        setMessages([]);
      } else {
        window.location.href = "/saved-prompts"; // Navigate without using React Router
      }
    },
    [handleClose, setMessages]
  );

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleOpenPromptFunc}
      />
      <Modal width="auto" open={isOpen} handleClose={() => handleClose()}>
        <Box height="auto" maxHeight="50vh" width="auto" minWidth="40vw" overflow="hidden">
          <FlexContainer alignVertical="space-between" breakDirection="row">
            <Heading>{t("LabelOpenPrompt")}</Heading>
            <LinkWrapper
              href="#"
              onClick={(e: any) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            </LinkWrapper>
          </FlexContainer>
          <Divider gap="3px" />
          <FlexContainer>
            <Text style={{ lineHeight: "1.5em" }}>
              {t("LabelOpenPromptConfirmation")}
            </Text>
          </FlexContainer>

          <FlexContainer
            gap={DefaultBoxGap}
            breakDirection="row"
            alignVertical="center"
            alignHorizontal="center"
          >
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={handleOpenFromCloud}
            >
              <IcoCloud margin="auto 10px -3px auto" size="16px" />
              {t("LabelOpenFromCloud")}
            </ButtonOutlinedDimmed>
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={triggerFileInput}
            >
              <IcoFolder margin="auto 10px -3px auto" size="16px" />
              {t("LabelOpenFromFile")}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        </Box>
      </Modal>
    </>
  );
};

export default OpenPromptModal;
