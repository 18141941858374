// src/hooks/usePrintPrompt.ts

import { useCallback } from "react";

export const usePrintSummary = (summary: string) => {
  const handlePrint = useCallback(() => {
    // Renamed from printPrompt to handlePrint
    const printWindow = window.open("", "_blank");
    const currentDate = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
    const messagesHtml = `<p style="padding:5px;"><strong>${summary}</p>`;

    printWindow?.document.write(`
          <html>
            <head>
              <title>AI Prompt Summart</title>
              <style>
                body { font-family: Arial, sans-serif; }
                p { margin: 5px 0; }
              </style>
            </head>
            <body>
              <figure class="image image-style-align-center" data-ckbox-resource-id="3lVXJGleS0sf">
                <picture>
                  <source srcset="https://ckbox.cloud/fed8209d07884597f7c6/assets/3lVXJGleS0sf/images/64.webp 64w" sizes="(max-width: 64px) 100vw, 64px" type="image/webp"><img src="https://ckbox.cloud/fed8209d07884597f7c6/assets/3lVXJGleS0sf/images/64.png" width="64" height="64">
                </picture>
              </figure>
              <figure>
              <h1 style="text-align:left;">AI Prompt Summary</h1>
              </figure>
              <figure class="table" style="float:left;width:100%;">
                <table class="ck-table-resized" style="border:1px solid hsl(0, 0%, 0%);">
                  <colgroup>
                    <col style="width:20%;">
                    <col style="width:25%;">
                    <col style="width:20%;">
                    <col style="width:35%;">
                  </colgroup>
                  <tbody>
                    <tr>
                      <td style="padding:10px;"><strong>Printed On:</strong></td>
                      <td style="padding:10px;">${currentDate}</td>
                      <td style="padding:10px;"><strong>Printed By:</strong></td>
                      <td style="padding:10px;">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </figure>
              <figure class="table" style="float:left;width:90%;">
                <table class="ck-table-resized">
                  <colgroup>
                    <col style="width:100%;">
                  </colgroup>
                  <thead>
                    <tr>
                      <th style="background-color:hsl(0, 0%, 90%);border:1px solid hsl(0, 0%, 0%);padding:10px;"><strong>Prompt Summary</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="border:1px solid hsl(0, 0%, 0%);padding:10px;"><pre>${messagesHtml}<pre></td>
                    </tr>
                  </tbody>
                </table>
              </figure>
              <figure class="table" style="width:90%;">
                <table class="ck-table-resized">
                  <colgroup>
                    <col style="width:100%;">
                  </colgroup>
                  <tbody>
                    <tr>
                      <td><span style="font-size:12px;">This report was generated by Arcadia, an AI Interface developed by <strong>Red Jack Studio Limited</strong>. The contents of this report are informative only. ¹ <i>Prompt files can be easily generated and manipulated by users. &nbsp;² The author of this report may not be the same author as the original prompt.</i></span></td>
                    </tr>
                  </tbody>
                </table>
              </figure>
            </body>
          </html>
        `);
    printWindow?.document.close();
    printWindow?.focus();
    setTimeout(() => {
      printWindow?.print();
      printWindow?.close();
    }, 2000); // Delay added to ensure images and videos are loaded before printing
  }, [summary]);

  return handlePrint; // Return the correctly named handler function
};
