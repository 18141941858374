import {
  Box,
  ButtonOutlinedDimmed,
  FlexContainer,
  Heading,
  HeadingSmall,
  Spacer,
  Text,
  TextSmall
} from "llane-ui";
import {
  AccentTransparentBlack,
  DefaultBorderRadius,
  DefaultBoxGap
} from "../../../theme";
import { useTranslate } from "../../../contexts/SettingContext";

const BillingSettings = () => {
  const { t } = useTranslate();

  return (
    <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
      <FlexContainer
        alignVertical="space-between"
        alignHorizontal="center"
        breakDirection="row"
        width="100%"
      >
        <Text>{t("LabelMySubscription")}</Text>
        <ButtonOutlinedDimmed margin="0" borderRadius={DefaultBorderRadius}>
          {t("LabelManage")}
        </ButtonOutlinedDimmed>
      </FlexContainer>
      <Spacer />
      <FlexContainer
        alignVertical="space-between"
        alignHorizontal="center"
        breakDirection="row"
        width="100%"
      >
        <Heading>{t("LabelAccountBalance")}</Heading>
        <Heading>
          <b>$0.00</b>
        </Heading>
      </FlexContainer>

      <Spacer gap="0" />

      <FlexContainer>
        <Box background={AccentTransparentBlack} gap={DefaultBoxGap} borderRadius="20px">
          <HeadingSmall>{t("LabelBillingHistory")}</HeadingSmall>
          <Spacer gap="0" />
          <TextSmall>{t("LabelNoItemsToShow")}</TextSmall>
        </Box>
      </FlexContainer>
    </FlexContainer>
  );
};

export default BillingSettings;
