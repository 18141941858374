import { AppWrapper, LlaneUi, useThemeToggle } from "llane-ui";
import AppHeader from "../components/Header";
import AppFooter from "../components/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import NewPromptModal from "../components/Modals/Confirmations/NewPromptModal";
import OpenPromptModal from "../components/Modals/Confirmations/OpenPromptModal";
import SavePromptModal from "../components/Modals/Confirmations/SavePromptModal";
import UpdatePromptModal from "../components/Modals/Confirmations/UpdatePromptModal";
import { useUserSetting } from "../contexts/SettingContext";
import { Dot, DotsLoader, LoaderPaddingWrapper } from "../theme";
import { useUser } from "../contexts/UserContext";
import SummariseModal from "../components/Modals/SummariseModal";
import { useEffect, useMemo } from "react";
import LoadingModal from "../components/Modals/LoadingModal";

const Modals = () => {
  return (
    <>
      <NewPromptModal />
      <OpenPromptModal />
      <SavePromptModal />
      <UpdatePromptModal />
      <SummariseModal />
      <LoadingModal />
    </>
  );
};

const Content = () => {
  const { setting } = useUserSetting();
  const { setTheme } = useThemeToggle();

  const currentTheme = useMemo(() => {
    if (setting) return setting.theme;
    else return undefined;
  }, [setting]);

  useEffect(() => {
    if (currentTheme) {
      setTheme(currentTheme);
    }
  }, [currentTheme, setTheme]);

  return (
    <AppWrapper
      alignHorizontal="center"
      alignVertical="top"
      height="100vh"
      style={{ overflow: "hidden" }}
    >
      <AppHeader />
      <Outlet />
      <AppFooter />
      <Modals />
    </AppWrapper>
  );
};

const AppLayout = () => {
  const { setting } = useUserSetting();

  const { user } = useUser();

  const navigate = useNavigate();

  const currentTheme = useMemo(() => {
    if (setting) return setting.theme;
    else return undefined;
  }, [setting]);

  if (user === null) {
    navigate("/login");
  }

  return (
    <>
      {!setting || (user && !currentTheme) ? (
        <AppWrapper alignHorizontal="center" alignVertical="top">
          <LoaderPaddingWrapper>
            <DotsLoader>
              <Dot delay="0s" />
              <Dot delay="0.1s" />
              <Dot delay="0.2s" />
            </DotsLoader>
          </LoaderPaddingWrapper>
        </AppWrapper>
      ) : (
        <LlaneUi initialTheme={setting.theme}>
          <Content />
        </LlaneUi>
      )}
    </>
  );
};

export default AppLayout;
