export const DefaultLanguage = 'en-uk';

export type Labels = Record<string, string>;

const loadLabels = async (language: string): Promise<Labels> => {
    try {
        const labels = await import(`./${language}.ts`);
        return labels.default;
    } catch (error) {
        console.error('Failed to load language:', error);
        // Fallback to default language if the requested language fails
        const labels = await import(`./${DefaultLanguage}.ts`);
        return labels.default;
    }
};

export default loadLabels;
