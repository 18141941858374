import { useCallback, useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";
import { fetchSetting, updateSetting } from "../api/settingApiCall";
import { useModal } from "../contexts/ModalContext";
import useLocalStorage from "./useLocalStorage";

const initSettng = {
  language: "en-uk",
  theme: undefined
};

export const useProviderSetting = () => {
  const [setting, setSetting] = useLocalStorage<any>("settings", null);
  const { user, accessToken } = useUser();

  const { handleOpen: openLoading, handleClose: closeLoading } =
    useModal("loading");

  const triggerFetch = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (user && user.user_id) {
        fetchSetting(user.user_id, accessToken)
          .then((setting) => {
            if (setting)
              setSetting((_prev: any) => {
                if (!_prev || !_prev.currentTheme) {
                  return {
                    ...setting,
                    currentTheme: setting.theme
                  };
                } else {
                  return {
                    ..._prev,
                    ...setting
                  };
                }
              });
            else setSetting(initSettng);
          })
          .catch((err) => {
            setSetting(initSettng);
          })
          .finally(() => {
            resolve();
          });
      } else if (user === null) {
        setSetting(initSettng);
        resolve();
      }
    });
  }, [user, accessToken]);

  const update = useCallback(
    (payload: any) => {
      openLoading();
      updateSetting({ ...payload, user_id: user.user_id }, accessToken)
        .then(async () => {
          await triggerFetch();
        })
        .catch(() => {})
        .finally(() => {
          closeLoading();
        });
    },
    [accessToken, user, openLoading, closeLoading, triggerFetch]
  );

  useEffect(() => {
    triggerFetch();
  }, [user, triggerFetch]);

  return {
    setting,
    triggerFetch,
    update
  };
};
