import styled, { keyframes } from "styled-components";
import { BorderColor } from "llane-ui";
import { BorderThickness } from "llane-ui";
import { AccentTransparentBlack, NeutralGray } from "../../theme";

const fadeInScrollUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const SuggestionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 10px;

  @media (max-width: 769px) {
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
  }
`;

export const SuggestionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  cursor: pointer;
  border-radius: 16px;
  border: ${BorderThickness} solid ${BorderColor};
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeInScrollUp} 0.5s ease-out forwards;
  &:hover {
    border: ${BorderThickness} solid ${NeutralGray};
    background: ${AccentTransparentBlack};
  }

  @media (max-width: 769px) {
    width: 100%;
    min-width: 100%;
  }
`;
