import {
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  InputOutlined,
  TextSmall,
  HeadingSmall,
  Spacer
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader
} from "../../../theme";
import { useTranslate } from "../../../contexts/SettingContext";
import { useUser } from "../../../contexts/UserContext";
import { useEffect, useState } from "react";
import { changePassword } from "../../../api/userApiCall";
import { toast } from "react-toastify";

const isEmpty = (value: any) => !value || value === "";

const SecuritySettings = () => {
  const { t } = useTranslate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [saving, setSaving] = useState(false);

  const { accessToken } = useUser();

  const handleChangePassword = () => {
    if (
      isEmpty(oldPassword) ||
      isEmpty(newPassword) ||
      isEmpty(confirmPassword)
    ) {
      return toast.error("can't change with empty value");
    }
    if (newPassword !== confirmPassword) {
      return toast.error("Please match Repeat New Password");
    }

    setSaving(true);

    const payload = {
      oldPassword,
      newPassword
    };

    changePassword(payload, accessToken)
      .then(() => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.success("successfully changed");
      })
      .catch((err) => {
        toast.error(err?.message ?? "Bad request");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }, []);

  return (
    <FlexContainer breakDirection="column" width="100%" gap={DefaultBoxGap}>
      <FlexContainer
        breakDirection="row"
        width="100%"
        gap={DefaultBoxGap}
        style={{ flexWrap: "wrap" }}
      >
        <FlexContainer
          padding="0 10px 0 10px"
          gap={DefaultBoxGap}
          width="100%"
          minWidth="230px"
          style={{ flex: 1 }}
        >
          <HeadingSmall>{t("Label2FactorAuth")}</HeadingSmall>
          <TextSmall style={{ lineHeight: "1.5em" }}>
            {t("Label2FactorAuthDescription")}
          </TextSmall>
        </FlexContainer>
        <FlexContainer padding="0 10px 0 10px">
          <ButtonOutlinedDimmed borderRadius={DefaultBorderRadius}>
            {t("LabelNotAvailable")}
          </ButtonOutlinedDimmed>
        </FlexContainer>
      </FlexContainer>
      <Divider />
      <FlexContainer
        breakDirection="row"
        style={{ flexWrap: "wrap" }}
        width="100%"
        gap={DefaultBoxGap}
      >
        <FlexContainer
          padding="0 10px 0 10px"
          gap={DefaultBoxGap}
          width="100%"
          minWidth="230px"
          style={{ flex: 1 }}
        >
          <HeadingSmall>{t("LabelUpdatePassword")}</HeadingSmall>
          <Spacer gap="0" />
          <FlexContainer breakDirection="row" gap={DefaultBoxGap}>
            <InputOutlined
              width="100%"
              placeholder={t("LabelOldPassword")}
              type="password"
              value={oldPassword}
              onChange={(e: any) => {
                setOldPassword(e.target.value);
              }}
            />
          </FlexContainer>
          <FlexContainer breakDirection="row" width="100%" gap={DefaultBoxGap}>
            <InputOutlined
              width="100%"
              placeholder={t("LabelNewPassword")}
              type="password"
              value={newPassword}
              onChange={(e: any) => {
                setNewPassword(e.target.value);
              }}
            />
          </FlexContainer>
          <FlexContainer breakDirection="row" width="100%" gap={DefaultBoxGap}>
            <InputOutlined
              width="100%"
              placeholder={t("LabelRepeatNewPassword")}
              type="password"
              value={confirmPassword}
              onChange={(e: any) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer padding="0 10px 0 10px">
          <ButtonOutlinedDimmed
            borderRadius={DefaultBorderRadius}
            onClick={() => handleChangePassword()}
          >
            {saving ? (
              <DotsLoader>
                <Dot delay="0s" />
                <Dot delay="0.1s" />
                <Dot delay="0.2s" />
              </DotsLoader>
            ) : (
              t("LabelUpdatePassword")
            )}
          </ButtonOutlinedDimmed>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default SecuritySettings;
