import { createContext, useContext } from "react";
import { useProviderSetting } from "../hooks/useProviderSetting";
import useLabels from "../hooks/useLabels";

interface SettingInterface {
  setting: any;
  update: Function;
  triggerFetch: Function;
  t: Function;
}

export const SettingContext = createContext<SettingInterface>({
  setting: {
    language: "en-uk",
    theme: undefined
  },
  update: () => {},
  triggerFetch: () => {},
  t: () => {}
});

export const useUserSetting = () => {
  const { setting, update, triggerFetch, t } = useContext(SettingContext);

  return { setting, update, triggerFetch, t };
};

export const useTranslate = () => {
  const { t } = useContext(SettingContext);

  return { t };
};

export const SettingProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { setting, update, triggerFetch } = useProviderSetting();
  const { t } = useLabels(setting?.language);

  return (
    <SettingContext.Provider value={{ setting, update, triggerFetch, t }}>
      {children}
    </SettingContext.Provider>
  );
};
