import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  Dropdown,
  DropdownLink,
  FlexContainer,
  Heading,
  HeadingSmall,
  IcoX,
  InputOutlined,
  LinkWrapper,
  Modal,
  Spacer,
  TextAreaOutlined,
  TextSmall
} from "llane-ui";
import { useEffect, useState } from "react";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../../theme";
import { FineTuneData, FineTuneForApi } from "../../types/fineTune";
import { fetchFineTuningById, updateFineTune } from "../../api/fineTuneApiCall";
import { FineTuningSelections } from "../../constants/fineTuningProfileSelections";
import { getJson } from "../../utils/isJson";
import { useUser } from "../../contexts/UserContext";
import { useTranslate } from "../../contexts/SettingContext";

const initData = {
  // AI_Name: "",
  // Company_Name: "",
  // AI_Working_Sector: "",
  // Specialization: "",
  // Main_Language: "",
  Behavior: {
    Response_Style: "",
    Preferred_Response_Length: ""
    // Base_Personality: ""
  },
  ExtraContext: "",
  ExtraParameters: ""
};

const sectors = FineTuningSelections.AI_Working_Sector;
const specializations = FineTuningSelections.Specialization;
const behavior = FineTuningSelections.Behavior;

const FineTuneEditModal = ({
  fine_tuning_id,
  open,
  handleClose,
  triggerFetch
}: {
  fine_tuning_id: number | undefined;
  open: boolean;
  handleClose: Function;
  triggerFetch: Function;
}) => {
  const { t } = useTranslate();

  const { accessToken } = useUser();

  const [profileName, setProfileName] = useState("");
  const [description, setDescription] = useState("");
  const [data, setData] = useState<FineTuneData>(initData);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChangeData = (value: any, key: string, key2?: string) => {
    setData((prev) => {
      let field = prev[key as keyof FineTuneData];
      if (key2) {
        (field as any)[key2] = value;
        return {
          ...prev,
          [key]: field
        };
      } else {
        return {
          ...prev,
          [key]: value
        };
      }
    });
  };

  const handleSubmit = () => {
    console.log(data);
    const extra = getJson(data.ExtraParameters);
    const payload: FineTuneForApi = {
      fine_tuning_id,
      fine_tuning_name: profileName,
      description,
      json_string: { ...data, ExtraParameters: extra }
    };

    setSaving(true);

    updateFineTune(payload, accessToken)
      .then(() => {
        triggerFetch();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSaving(false);
        handleClose();
      });
  };

  useEffect(() => {
    setProfileName("");
    setDescription("");
    setData(initData);

    if (fine_tuning_id && accessToken) {
      setLoading(true);
      fetchFineTuningById(fine_tuning_id, accessToken)
        .then((response: any) => {
          if (
            response &&
            response.fine_tuning_id &&
            response.fine_tuning_name &&
            response.json_string
          ) {
            const name = response.fine_tuning_name;
            const description = response.description;
            setProfileName(name);
            setDescription(description);
            const json_string = JSON.parse(response.json_string);
            const extra = JSON.stringify(json_string?.ExtraParameters);
            setData({ ...json_string, ExtraParameters: extra });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fine_tuning_id, accessToken]);

  return (
    <>
      <Modal width="auto" open={open} handleClose={() => handleClose()}>
        {/* Header */}
        <Box
          height="auto"
          width="750px"
          minHeight="60vh"
          maxHeight="90vh"
          overflow="hidden"
        >
          <FlexContainer alignVertical="space-between" breakDirection="row">
            <Heading>{t("LabelFineTuningProfile")}</Heading>
            <LinkWrapper
              href="#"
              onClick={() => {
                handleClose();
              }}
            >
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            </LinkWrapper>
          </FlexContainer>
          <Divider gap="3px" />
          {/* Body */}
          <>
            {loading ? (
              <LoaderPaddingWrapper>
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              </LoaderPaddingWrapper>
            ) : (
              <FlexContainer
                style={{
                  overflow: "hidden"
                }}
              >
                <FlexContainer
                  breakDirection="column"
                  width="100%"
                  gap={DefaultBoxGap}
                  padding="0 10px 0 10px"
                  style={{ overflow: "auto", boxSizing: "border-box" }}
                >
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      gap={DefaultBoxGap}
                      minWidth="230px"
                      style={{ flex: 1 }}
                    >
                      <HeadingSmall>{t("LabelProfileName")}</HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelProfileNameDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      style={{ flex: 1 }}
                      minWidth="150px"
                      alignHorizontal="center"
                      alignVertical="center"
                    >
                      <InputOutlined
                        value={profileName}
                        margin="0"
                        width="100%"
                        onChange={(e: any) => {
                          setProfileName(e.target.value);
                        }}
                        placeholder={t("LabelProfileName")}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <Divider />
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>
                        {t("LabelProfileDescription")}
                      </HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelProfileDescriptionDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="150px"
                      style={{ flex: 1 }}
                      alignHorizontal="center"
                      alignVertical="center"
                    >
                      <TextAreaOutlined
                        value={description}
                        onChange={(e: any) => setDescription(e.target.value)}
                        style={{ margin: 0 }}
                        width="100%"
                        placeholder={t("LabelProfileDescription")}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <Divider />
                  {/* <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>{t("LabelAssistantName")}</HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelAssistantNameDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="150px"
                      style={{ flex: 1 }}
                      alignHorizontal="center"
                      alignVertical="center"
                    >
                      <InputOutlined
                        placeholder={t("LabelAssistantName")}
                        value={data.AI_Name}
                        style={{ margin: 0 }}
                        width="100%"
                        onChange={(e: any) => {
                          setData((prev) => ({
                            ...prev,
                            AI_Name: e.target.value
                          }));
                        }}
                      />
                    </FlexContainer>
                  </FlexContainer> */}
                  {/* <Divider />
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      padding="0 10px 0 10px"
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>
                        {t("LabelCompanyWorkProfile")}
                      </HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelCompanyWorkProfileDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      alignVertical="center"
                      gap="5px"
                    >
                      <Dropdown
                        title={
                          data.Company_Name
                            ? data.Company_Name
                            : t("LabelSelectCompany")
                        }
                      >
                        <DropdownLink
                          onClick={() =>
                            handleChangeData(undefined, t("LabelCompany"))
                          }
                        >
                          {t("LabelNone")}
                        </DropdownLink>
                        <DropdownLink
                          onClick={() =>
                            handleChangeData("Company Name", "Company")
                          }
                        >
                          {t("LabelCompanyName")}
                        </DropdownLink>
                      </Dropdown>
                      <Dropdown
                        title={
                          !data.AI_Working_Sector ||
                          data.AI_Working_Sector === ""
                            ? t("LabelSector")
                            : t(data.AI_Working_Sector)
                        }
                      >
                        <DropdownLink
                          onClick={() =>
                            handleChangeData("", "AI_Working_Sector")
                          }
                        >
                          {t("LabelNone")}
                        </DropdownLink>
                        {sectors.map((item) => (
                          <DropdownLink
                            key={item}
                            onClick={() =>
                              handleChangeData(item, "AI_Working_Sector")
                            }
                          >
                            {t(item)}
                          </DropdownLink>
                        ))}
                      </Dropdown>
                      <Dropdown
                        title={
                          !data.Specialization || data.Specialization === ""
                            ? t("LabelSpecialisation")
                            : t(data.Specialization)
                        }
                      >
                        <DropdownLink
                          onClick={() => handleChangeData("", "Specialization")}
                        >
                          {t("LabelNone")}
                        </DropdownLink>
                        {specializations.map((item) => (
                          <DropdownLink
                            key={item}
                            onClick={() =>
                              handleChangeData(item, "Specialization")
                            }
                          >
                            {t(item)}
                          </DropdownLink>
                        ))}
                      </Dropdown>
                    </FlexContainer>
                  </FlexContainer>
                  <Divider /> */}
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      padding="0 10px 0 10px"
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>
                        {t("LabelBehaviourControls")}{" "}
                      </HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelBehaviourControlsDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      alignVertical="center"
                      gap="5px"
                    >
                      <Dropdown
                        title={
                          !data.Behavior.Response_Style ||
                          data.Behavior.Response_Style === ""
                            ? t("LabelResponseStyle")
                            : data.Behavior.Response_Style
                        }
                      >
                        {behavior.Response_Style.map((item) => (
                          <DropdownLink
                            key={item}
                            onClick={() => {
                              handleChangeData(
                                item,
                                "Behavior",
                                "Response_Style"
                              );
                            }}
                          >
                            {item}
                          </DropdownLink>
                        ))}
                      </Dropdown>
                      <Dropdown
                        title={
                          !data.Behavior.Preferred_Response_Length ||
                          data.Behavior.Preferred_Response_Length === ""
                            ? t("LabelResponseLength")
                            : data.Behavior.Preferred_Response_Length
                        }
                      >
                        {behavior.Preferred_Response_Length.map((item) => (
                          <DropdownLink
                            key={item}
                            onClick={() => {
                              handleChangeData(
                                item,
                                "Behavior",
                                "Preferred_Response_Length"
                              );
                            }}
                          >
                            {item}
                          </DropdownLink>
                        ))}
                      </Dropdown>
                      {/* <Dropdown
                        title={
                          !data.Behavior.Base_Personality ||
                          data.Behavior.Base_Personality === ""
                            ? t("LabelBasePersonality")
                            : data.Behavior.Base_Personality
                        }
                      >
                        {behavior.Base_Personality.map((item) => (
                          <DropdownLink
                            key={item}
                            onClick={() => {
                              handleChangeData(
                                item,
                                "Behavior",
                                "Base_Personality"
                              );
                            }}
                          >
                            {item}
                          </DropdownLink>
                        ))}
                      </Dropdown> */}
                    </FlexContainer>
                  </FlexContainer>
                  <Divider />
                  {/* <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      padding="0 10px 0 10px"
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>{t("LabelMainLanguage")}</HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelMainLanguageDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="150px"
                      style={{ flex: 1 }}
                      alignHorizontal="center"
                      alignVertical="center"
                      gap="5px"
                    >
                      <InputOutlined
                        placeholder={t("LabelLanguageEgPlaceholder")}
                        value={data.Main_Language}
                        onChange={(e: any) => {
                          handleChangeData(e.target.value, "Main_Language");
                        }}
                        margin="0"
                        width="100%"
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <Divider /> */}
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      padding="0 10px 0 10px"
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>{t("LabelExtraContext")}</HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelExtraContextDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="150px"
                      style={{ flex: 1 }}
                      alignHorizontal="center"
                      alignVertical="center"
                      gap="5px"
                    >
                      <InputOutlined
                        placeholder={t("LabelExtraContext")}
                        value={data.ExtraContext}
                        onChange={(e: any) => {
                          handleChangeData(e.target.value, "ExtraContext");
                        }}
                        margin="0"
                        width="100%"
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <Divider />
                  <FlexContainer
                    breakDirection="row"
                    width="100%"
                    gap={DefaultBoxGap}
                    style={{ flexWrap: "wrap" }}
                  >
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      padding="0 10px 0 10px"
                      gap={DefaultBoxGap}
                    >
                      <HeadingSmall>{t("LabelExtraParameters")}</HeadingSmall>
                      <TextSmall style={{ lineHeight: "1.5em" }}>
                        {t("LabelExtraParametersDescription")}
                      </TextSmall>
                    </FlexContainer>
                    <FlexContainer
                      minWidth="230px"
                      style={{ flex: 1 }}
                      alignHorizontal="center"
                      alignVertical="center"
                      gap="5px"
                    >
                      <TextAreaOutlined
                        value={data.ExtraParameters}
                        onChange={(e: any) => {
                          handleChangeData(e.target.value, "ExtraParameters");
                        }}
                        placeholder={t("LabelExtraParametersTxtArea")}
                        rows={6}
                        width="100%"
                        style={{ margin: 0 }}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <Spacer />

                  {/* Buttons */}
                </FlexContainer>
                <Spacer gap="0" />
                <FlexContainer
                  breakDirection="row"
                  alignVertical="flex-end"
                  gap={DefaultBoxGap}
                >
                  <ButtonOutlinedDimmed
                    margin="0"
                    borderRadius={DefaultBorderRadius}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t("LabelCancel")}
                  </ButtonOutlinedDimmed>
                  <ButtonOutlinedDimmed
                    margin="0"
                    borderRadius={DefaultBorderRadius}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {saving ? (
                      <DotsLoader>
                        <Dot delay="0s" />
                        <Dot delay="0.1s" />
                        <Dot delay="0.2s" />
                      </DotsLoader>
                    ) : (
                      t("LabelSaveClose")
                    )}
                  </ButtonOutlinedDimmed>
                </FlexContainer>
              </FlexContainer>
            )}
          </>
        </Box>
      </Modal>
    </>
  );
};

export default FineTuneEditModal;
