import { useCallback, useEffect, useMemo, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { getUser } from "../api/userApiCall";

export const useProvideAuth = () => {
  const [accessTokenInLocalStorage, setAccessTokenInLocalStorate] =
    useLocalStorage("access-token", "");
  const [_accessToken, _setAccessToken] = useState("");
  const [loginType, setLoginType] = useState(false);

  const [user, setUser] = useState<any>();

  const accessToken = useMemo(() => {
    if (_accessToken && _accessToken !== "") {
      return _accessToken;
    } else if (accessTokenInLocalStorage && accessTokenInLocalStorage !== "") {
      return accessTokenInLocalStorage;
    } else return "";
  }, [accessTokenInLocalStorage, _accessToken]);

  const setAccessToken = useCallback(
    (token: string) => {
      if (loginType) {
        return setAccessTokenInLocalStorate(token);
      } else {
        return _setAccessToken(token);
      }
    },
    [loginType, setAccessTokenInLocalStorate, _setAccessToken]
  );

  const triggerFetch = useCallback(() => {
    if (accessToken && accessToken !== "") {
      getUser(accessToken)
        .then((user) => {
          if (user) {
            setUser(user);
          }
        })
        .catch((err) => {
          setUser(null);
          console.error(err.message);
        });
    } else {
      setUser(null);
    }
  }, [accessToken, setUser]);

  const logout = useCallback(() => {
    setAccessTokenInLocalStorate("");
    _setAccessToken("");
  }, [setAccessTokenInLocalStorate, _setAccessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return {
    accessToken,
    setAccessToken,
    user,
    triggerFetch,
    logout,
    loginType,
    setLoginType
  };
};
