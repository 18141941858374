import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoFeather,
  IcoX,
  LinkWrapper,
  Modal,
  Text
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader
} from "../../theme";
import { useModal } from "../../contexts/ModalContext";
import { usePrompt } from "../../contexts/PromptContext";
import { handleSaveSummary } from "../../utils/saveToFile";
import React, { useCallback, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { useTranslate } from "../../contexts/SettingContext";
import { sendMessageToAi } from "../../api/aiApiCall";
import { usePrintSummary } from "../../hooks/usePrintSummary";
import { PromptSummary } from "../../constants/preFormattedRequests";

const SummariseModal = () => {
  const [generatingSummary, setgeneratingSummary] = useState(false);
  const { isOpen, handleClose } = useModal("summary", generatingSummary);
  const { messages } = usePrompt();
  const { accessToken } = useUser();

  const [summarise, setSummarise] = useState<any>();

  const { t } = useTranslate();

  const handlePrint = usePrintSummary(summarise);

  const handleSummarise = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setgeneratingSummary(true);

      const payload = {
        context: messages,
        content: PromptSummary
      };

      sendMessageToAi("GPT35", payload, accessToken)
        .then((response) => {
          if (response) {
            if (response.choices?.[0]?.message?.content) {
              const summarise = `SUMMARY OF ARCADIA PROMPT \n\n${response.choices[0].message.content}`;
              setSummarise(summarise);
            }
            // add success logic here . ex. show success alert
          }
          setgeneratingSummary(false);
        })
        .catch(() => {
          setgeneratingSummary(false);
        });
    },
    [messages, accessToken]
  );

  return (
    <Modal
      width="auto"
      open={isOpen}
      handleClose={() => {
        if (!generatingSummary) {
          setSummarise(undefined);
          handleClose();
        }
      }}
    >
      <Box height="auto" maxHeight="50vh" width="auto" overflow="hidden">
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>
            <IcoFeather margin="0 10px -3px 0" />
            {t("Smart Summary")}
          </Heading>
          <LinkWrapper
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              setSummarise(undefined);
              handleClose();
            }}
          >
            {!generatingSummary && (
              <Box padding="0">
                <IcoX margin="0" />
              </Box>
            )}
          </LinkWrapper>
        </FlexContainer>
        <Divider gap="3px" />
        <FlexContainer>
          <Text style={{ lineHeight: "1.5em" }}>
            {!summarise
              ? `${t("LabelSummaryConfirmation")}`
              : `${t("LabelSummaryDone")}`}
          </Text>
        </FlexContainer>

        {summarise && (
          <FlexContainer gap="10px">
            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => {
                handleSaveSummary(summarise);
              }}
            >
              {t("LabelSummaryDownload")}
            </ButtonOutlinedDimmed>

            <ButtonOutlinedDimmed
              margin="0"
              borderRadius={DefaultBorderRadius}
              onClick={() => {
                handlePrint();
              }}
            >
              {t("LabelPrint")}
            </ButtonOutlinedDimmed>
          </FlexContainer>
        )}
        <FlexContainer
          gap={DefaultBoxGap}
          width="100%"
          breakDirection="row"
          alignVertical="flex-end"
        >
          {!summarise && (
            <>
              {!generatingSummary && (
                <ButtonOutlinedDimmed
                  margin="0"
                  borderRadius={DefaultBorderRadius}
                  onClick={() => {
                    setSummarise(undefined);
                    handleClose();
                  }}
                >
                  {t("LabelCancel")}
                </ButtonOutlinedDimmed>
              )}
              <ButtonOutlinedDimmed
                margin="0"
                borderRadius={DefaultBorderRadius}
                onClick={handleSummarise}
              >
                {generatingSummary ? (
                  <DotsLoader>
                    <Dot delay="0s" />
                    <Dot delay="0.1s" />
                    <Dot delay="0.2s" />
                  </DotsLoader>
                ) : (
                  <>{t("LabelProceed")}</>
                )}
              </ButtonOutlinedDimmed>
            </>
          )}
        </FlexContainer>
      </Box>
    </Modal>
  );
};

export default SummariseModal;
