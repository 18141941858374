import { useTranslate } from "../contexts/SettingContext";
import {
  Box,
  ButtonLink,
  Container,
  FlexContainer,
  HeadingExtraLarge,
  HeadingSmall,
  PageWrapper,
  Spacer,
  Text
} from "llane-ui";

const Home = () => {
  const { t } = useTranslate();

  return (
    <PageWrapper>
      <Spacer gap="10%" />
      <FlexContainer
        breakDirection="row"
        alignHorizontal="center"
        alignVertical="center"
      >
        <Box padding="50px 50px" alignText="center">
          <Container>
            <HeadingExtraLarge>{t("LabelWelcomeToArcadia")}</HeadingExtraLarge>
            <Spacer gap="5px" />
            <HeadingSmall>{t("LabelSelectOneOptionBelow")}</HeadingSmall>
          </Container>
          <FlexContainer breakDirection="row">
            <FlexContainer width="50%" alignText="center">
              <Text>{t("LabelAlreadyHaveAccount")}</Text>
              <Spacer gap="1.2rem" />
              <ButtonLink href="/login">{t("LabelLoginToArcadia")}</ButtonLink>
            </FlexContainer>
            <FlexContainer width="50%" alignText="center">
              <Text>{t("LabelDontHaveAccount")}</Text>
              <Spacer gap="1.2rem" />
              <ButtonLink href="/signup">
                {t("LabelSignupToArcadia")}
              </ButtonLink>
            </FlexContainer>
          </FlexContainer>
        </Box>
      </FlexContainer>
      <Spacer gap="10%" />
    </PageWrapper>
  );
};

export default Home;
