import { useCallback, useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";
import { fetchFineTuneByUserId } from "../api/fineTuneApiCall";

export const useFineTunings = () => {
  const [fineTunings, setFineTunings] = useState<any[] | undefined>();
  const { user, accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    if (user && user.user_id && accessToken) {
      fetchFineTuneByUserId(user.user_id, accessToken)
        .then((data) => {
          if (data && Array.isArray(data)) {
            setFineTunings(data);
          } else {
            setFineTunings([]);
          }
        })
        .catch(() => {
          setFineTunings([]);
        });
    }
  }, [user, setFineTunings, accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { fineTunings, triggerFetch };
};
