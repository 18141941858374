import { useCallback, useContext, useMemo } from "react";
import {
  Divider,
  Header,
  HeaderSection,
  Heading,
  // IcoCloud,
  IcoCommand,
  IcoEdit3,
  // IcoFile,
  IcoFolder,
  IcoHelpCircle,
  IcoMessageCircle,
  IcoPrinter,
  IcoSave,
  IcoShare,
  // IcoShare2,
  // IcoShoppingBag,
  IcoX,
  LinkWrapper,
  Submenu,
  SubmenuLink,
  Text,
  TextDimmed
  // ThemeToggle
} from "llane-ui";
import SettingsModal from "../Modals/SettingsModal";
import { usePrompt } from "../../contexts/PromptContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { useModal } from "../../contexts/ModalContext";
import { usePrintPrompt } from "../../hooks/usePrintPrompt";
import { useTranslate } from "../../contexts/SettingContext";
import ResponsiveHeaderLinks, {
  MobileMenuContext
} from "./ResponsiveHeaderLinks";
import styled from "styled-components";

const LogoWrapper = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
`;

const WrappedLink = ({ onClick, children, ...props }: any) => {
  const { setOpen } = useContext(MobileMenuContext);
  return (
    <SubmenuLink
      {...props}
      onClick={(e: any) => {
        setOpen(false);
        onClick(e);
      }}
    >
      {children}
    </SubmenuLink>
  );
};

export function AppHeader() {
  const { t } = useTranslate();
  const { messages, setMessages, setPromptId } = usePrompt();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const printPrompt = usePrintPrompt();

  const { toggleModal: toggleNewPromptModal } = useModal("newPrompt");
  const { toggleModal: toggleOpenPromptModal } = useModal("openPrompt");
  const { toggleModal: toggleSavePromptModal } = useModal("savePrompt");
  const { toggleModal: toggleUpdatePromptModal } = useModal("updatePrompt");

  const { setOpen: setMobileOpen } = useContext(MobileMenuContext);

  const isEmpty = useMemo(() => messages.length === 0, [messages]);

  const isNewPrompt = useMemo(() => {
    const promptId = searchParams.get("prompt_id");
    if (promptId && !Number.isNaN(promptId)) {
      return false;
    } else {
      return true;
    }
  }, [searchParams]);

  const fileIsOpen = useMemo(() => {
    return location.pathname === "/prompt";
  }, [location]);

  const handleCloseClick = useCallback(
    (event: any) => {
      event.preventDefault();
      if (location.pathname === "/prompt") {
        setMessages([]);
        setPromptId(undefined);
      }
    },
    [setMessages, setPromptId, location]
  );

  return (
    <Header>
      <ResponsiveHeaderLinks>
        <LogoWrapper>
          <LinkWrapper href="/">
            <Heading margin="0.5rem 1.5rem 0.5rem 0.5rem">Arcadia</Heading>
          </LinkWrapper>
        </LogoWrapper>
        <Submenu title={t("LabelFile")}>
          {!isEmpty && fileIsOpen ? (
            <SubmenuLink
              title={t("LabelNewPromptDescription")}
              onClick={(e: any) => {
                e.preventDefault();
                toggleNewPromptModal();
              }}
            >
              <Text>
                <IcoSave size="20px" margin="auto 10px -5px auto" />
                {t("LabelNewPrompt")}
              </Text>
            </SubmenuLink>
          ) : (
            <SubmenuLink href="/prompt" title={t("LabelNewPromptDescription")}>
              <Text>
                <IcoSave size="20px" margin="auto 10px -5px auto" />
                {t("LabelNewPrompt")}
              </Text>
            </SubmenuLink>
          )}
          <WrappedLink
            href="#"
            title={t("LabelOpenPromptDescription")}
            onClick={(e: any) => {
              e.preventDefault();
              console.log("here");
              setMobileOpen(false);
              toggleOpenPromptModal();
            }}
          >
            <Text>
              <IcoFolder size="20px" margin="auto 10px -5px auto" />
              {t("LabelOpenPrompt")}
            </Text>
          </WrappedLink>
          {!isEmpty && fileIsOpen ? (
            <WrappedLink
              title={t("LabelSavePromptDescription")}
              onClick={(e: any) => {
                e.preventDefault();
                if (isNewPrompt) {
                  toggleSavePromptModal();
                } else {
                  toggleUpdatePromptModal();
                }
              }}
            >
              <Text>
                <IcoSave size="20px" margin="auto 10px -5px auto" />
                {t("LabelSavePrompt")}
              </Text>
            </WrappedLink>
          ) : (
            <WrappedLink title={t("LabelSavePromptDescription")}>
              <TextDimmed>
                <IcoSave size="20px" margin="auto 10px -5px auto" />
                {t("LabelSavePrompt")}
              </TextDimmed>
            </WrappedLink>
          )}
          {!isEmpty && fileIsOpen ? (
            <WrappedLink
              title={t("LabelSaveCopyDescription")}
              onClick={(e: any) => {
                e.preventDefault();
                toggleSavePromptModal();
              }}
            >
              <Text>
                <IcoSave size="20px" margin="auto 10px -5px auto" />
                {t("LabelSaveCopy")}
              </Text>
            </WrappedLink>
          ) : (
            <WrappedLink title={t("LabelSaveCopyDescription")}>
              <TextDimmed>
                <IcoSave size="20px" margin="auto 10px -5px auto" />
                {t("LabelSaveCopy")}
              </TextDimmed>
            </WrappedLink>
          )}

          <Divider gap="0.5rem" />

          {!isEmpty && fileIsOpen ? (
            <WrappedLink
              href="#"
              title={t("LabelPrintDescription")}
              onClick={(e: any) => {
                e.preventDefault();
                printPrompt();
              }}
            >
              <Text>
                <IcoPrinter size="20px" margin="auto 10px -5px auto" />
                {t("LabelPrint")}
              </Text>
            </WrappedLink>
          ) : (
            <WrappedLink title={t("LabelPrintDescription")}>
              <TextDimmed>
                <IcoPrinter size="20px" margin="auto 10px -5px auto" />
                {t("LabelPrint")}
              </TextDimmed>
            </WrappedLink>
          )}
          <WrappedLink href="#" title={t("LabelShareDescription")}>
            {!isEmpty && fileIsOpen ? (
              <Text>
                <IcoShare size="20px" margin="auto 10px -5px auto" />
                {t("LabelShare")}
              </Text>
            ) : (
              <TextDimmed>
                <IcoShare size="20px" margin="auto 10px -5px auto" />
                {t("LabelShare")}
              </TextDimmed>
            )}
          </WrappedLink>
          <WrappedLink href="/prompt" title={t("LabelHelpDescription")}>
            <Text>
              <IcoHelpCircle size="20px" margin="auto 10px -5px auto" />
              {t("LabelHelp")}
            </Text>
          </WrappedLink>
          <WrappedLink
            href="#"
            title="Closes the current file."
            onClick={(event: any) => handleCloseClick(event)}
          >
            {!isEmpty && fileIsOpen ? (
              <Text>
                <IcoX size="20px" margin="auto 10px -5px auto" />
                {t("LabelClose")}
              </Text>
            ) : (
              <TextDimmed>
                <IcoX size="20px" margin="auto 10px -5px auto" />
                {t("LabelClose")}
              </TextDimmed>
            )}
          </WrappedLink>
        </Submenu>
        <Submenu title={t("LabelMyAssistant")}>
          {/*
            <SubmenuLink
              href="#"
              target="_self"
              title={t("LabelMarketplaceDescription")}
            >
              <Text>
                <IcoShoppingBag size="20px" margin="auto 10px -5px auto" />
                {t("LabelMarketplace")}
              </Text>
            </SubmenuLink>
            */}
          <SubmenuLink
            href="/saved-prompts"
            target="_self"
            title={t("LabelFineTuningProfilesDescription")}
          >
            <Text>
              <IcoMessageCircle size="20px" margin="auto 10px -5px auto" />
              {t("LabelSavedPrompts")}
            </Text>
          </SubmenuLink>
          <SubmenuLink
            href="/fine-tune"
            target="_self"
            title={t("LabelFineTuningProfilesDescription")}
          >
            <Text>
              <IcoEdit3 size="20px" margin="auto 10px -5px auto" />
              {t("LabelFineTuningProfiles")}
            </Text>
          </SubmenuLink>
          <SubmenuLink
            href="/custom-commands"
            target="_self"
            title={t("LabelCustomCommandsDescription")}
          >
            <Text>
              <IcoCommand size="20px" margin="auto 10px -5px auto" />
              {t("LabelCustomCommands")}
            </Text>
          </SubmenuLink>
        </Submenu>
        {/*
          <Submenu title={t("LabelNetworkAndCloud")}>
            <SubmenuLink
              href="/drive"
              target="_self"
              title={t("LabelMyCloudDescription")}
            >
              <Text>
                <IcoCloud size="20px" margin="auto 10px -5px auto" />
                {t("LabelMyCloud")}
              </Text>
            </SubmenuLink>
            <SubmenuLink
              href="/drive"
              target="_self"
              title={t("LabelSharedFilesDescription")}
            >
              <Text>
                <IcoShare2 size="20px" margin="auto 10px -5px auto" />
                {t("LabelSharedFiles")}
              </Text>
            </SubmenuLink>
          </Submenu>
            */}
      </ResponsiveHeaderLinks>
      <HeaderSection>
        {/* <FlexContainer margin="auto 1rem">
          <ThemeToggle />
        </FlexContainer> */}
        <SettingsModal />
      </HeaderSection>
    </Header>
  );
}

export default AppHeader;
