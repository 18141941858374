import { useUser } from "../contexts/UserContext";
import { Outlet, useNavigate } from "react-router-dom";
import { AppWrapper } from "llane-ui";

export const AuthLayout = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  if (user) {
    navigate("/prompt");
  }

  return (
    <AppWrapper alignHorizontal="center" alignVertical="top">
      <Outlet />
    </AppWrapper>
  );
};
