const downloadToFile = (
  content: BlobPart,
  filename: string,
  contentType: string
) => {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
};

export const handleSavePrompt = (messages: any[]) => {
  if (!messages.length) return; // Check if there are messages to save

  // Generate a timestamp for the filename
  const now = new Date();
  const timestamp = `${now.getDate().toString().padStart(2, "0")}${(
    now.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${now.getFullYear().toString().slice(-2)}${now
    .getHours()
    .toString()
    .padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}${now
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
  const filename = `Arcadia_${timestamp}.prompt`;

  const serializedData = JSON.stringify(messages, null, 2); // Pretty print the JSON
  downloadToFile(serializedData, filename, "application/json");
};

export const handleSaveSummary = (summary: string) => {
  // Generate a timestamp for the filename
  const now = new Date();
  const timestamp = `${now.getDate().toString().padStart(2, "0")}${(
    now.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}${now.getFullYear().toString().slice(-2)}${now
    .getHours()
    .toString()
    .padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}${now
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
  const filename = `Arcadia_Prompt-Summary_${timestamp}.txt`;

  const serializedData = summary; // Pretty print the JSON
  downloadToFile(serializedData, filename, "txt");
};
