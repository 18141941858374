import { Box, Modal } from "llane-ui";
import { Dot, DotsLoader } from "../../theme";
import { useModal } from "../../contexts/ModalContext";

const LoadingModal = () => {
  const { isOpen } = useModal("loading");

  return (
    <Modal width="auto" maxWidth="100px" open={isOpen} handleClose={() => {}}>
      <Box overflow="hidden">
        <DotsLoader>
          <Dot delay="0s" />
          <Dot delay="0.1s" />
          <Dot delay="0.2s" />
        </DotsLoader>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
