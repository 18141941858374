import { Dropdown, DropdownLink } from "llane-ui";
import { SelectList } from "../constants";

interface SortProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const Sort = ({ selected, setSelected }: SortProps) => {
  return (
    <Dropdown title={selected} position="right">
      {SelectList.map((item) => (
        <DropdownLink onClick={() => setSelected(item)}>{item}</DropdownLink>
      ))}
    </Dropdown>
  );
};

export default Sort;
