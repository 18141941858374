import {
  ButtonOutlinedDimmed,
  FlexContainer,
  Heading,
  HeadingLarge,
  IcoEdit,
  IcoEdit3,
  IcoTrash2,
  PageWrapper,
  ResponsiveBox,
  Spacer,
  Text,
  ToggleButton
} from "llane-ui";
import {
  AccentTransparentBlack,
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper,
  PageWrapperMargin,
  ResponsiveHeadContainer
} from "../theme";
import NewFineTuningModal from "../components/Modals/NewFineTuneModal";
import { useFineTunings } from "../hooks/useFineTunings";
import CommonModal from "../components/Modals/Confirmations/CommonModal";
import { useCallback, useMemo, useState } from "react";
import { removeFineTune } from "../api/fineTuneApiCall";
import { useUser } from "../contexts/UserContext";
import FineTuneEditModal from "../components/Modals/FineTuneEditModal";
import { useTranslate } from "../contexts/SettingContext";
import Search from "../components/Search";
import { useSearch } from "../hooks/useSearch";
import Sort from "../components/Sort";
import { SelectList } from "../constants";
import { useUserFineTuning } from "../hooks/useUserFineTunings";

export function FineTune() {
  const { t } = useTranslate();

  const [selected, setSelected] = useState(SelectList[0]);

  const { fineTunings, triggerFetch } = useFineTunings();

  const { userFineTuning, saveUserFineTuning } = useUserFineTuning();

  const { accessToken } = useUser();

  const search = useSearch();

  const [fineTuningIdToRemove, setFineTuningIdToRemove] = useState<
    number | undefined
  >(undefined);

  const [fineTuningIdToEdit, setFineTuningIdToEdit] = useState<
    number | undefined
  >(undefined);

  const handleSetDefaultFineTuning = (fineTuningId: number) => {
    saveUserFineTuning(fineTuningId);
  };

  const removeFineTuneCallback = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (fineTuningIdToRemove)
        removeFineTune(fineTuningIdToRemove, accessToken)
          .then(() => {
            triggerFetch();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
    });
  }, [fineTuningIdToRemove, triggerFetch, accessToken]);

  const handleRemoveFineTuning = (fine_tuning_id: number) => {
    setFineTuningIdToRemove(fine_tuning_id);
  };

  const searched = useMemo(() => {
    if (!fineTunings) return undefined;
    let _fineTunings = [...fineTunings];
    if (search) {
      _fineTunings = _fineTunings?.filter((fineTune) => {
        if (
          fineTune?.fine_tuning_name?.includes(search) ||
          fineTune?.fine_tuning_name?.toLowerCase().includes(search)
        )
          return true;
        else if (
          fineTune?.json_string?.includes(search) ||
          fineTune?.json_string?.toLowerCase().includes(search)
        )
          return true;
        else return false;
      });
    }

    if (selected !== "Default") {
      _fineTunings.sort((a: any, b: any) => {
        if (selected === "Date, Newest First") {
          const aDate = new Date(a.last_update_date);
          const bDate = new Date(b.last_update_date);

          return aDate > bDate ? -1 : aDate === bDate ? 0 : 1;
        } else if (selected === "Date, Oldest First") {
          const aDate = new Date(a.last_update_date);
          const bDate = new Date(b.last_update_date);

          return aDate > bDate ? 1 : aDate === bDate ? 0 : -1;
        } else if (selected === "Alphabetical, A-Z") {
          return a.fine_tuning_name.toLowerCase() >
            b.fine_tuning_name.toLowerCase()
            ? 1
            : a.fine_tuning_name.toLowerCase() ===
              b.fine_tuning_name.toLowerCase()
            ? 0
            : -1;
        } else if (selected === "Alphabetical, Z-A") {
          return a.fine_tuning_name.toLowerCase() >
            b.fine_tuning_name.toLowerCase()
            ? -1
            : a.fine_tuning_name.toLowerCase() ===
              b.fine_tuning_name.toLowerCase()
            ? 0
            : 1;
        } else return 0;
      });
    }

    return _fineTunings;
  }, [fineTunings, search, selected]);

  return (
    <PageWrapper margin={PageWrapperMargin}>
      <ResponsiveBox style={{ overflow: "hidden" }} height="auto">
        <FlexContainer
          breakDirection="column"
          gap={DefaultBoxGap}
          style={{ overflow: "hidden" }}
          height="100%"
        >
          <ResponsiveHeadContainer
            breakDirection="row"
            alignHorizontal="center"
            alignVertical="center"
            gap={DefaultBoxGap}
          >
            <FlexContainer style={{ marginRight: "auto" }}>
              <HeadingLarge>{t("LabelFineTuningProfiles")}</HeadingLarge>
            </FlexContainer>
            <FlexContainer
              breakDirection="row"
              alignHorizontal="center"
              style={{ flex: 1, flexWrap: "wrap" }}
              gap={DefaultBoxGap}
              width="100%"
            >
              <FlexContainer style={{ flex: 1 }}>
                <Search />
              </FlexContainer>
              <FlexContainer
                alignHorizontal="center"
                alignVertical="flex-end"
                breakDirection="row"
                style={{ marginLeft: "auto" }}
              >
                <Sort selected={selected} setSelected={setSelected} />
                <NewFineTuningModal triggerFetch={triggerFetch} />
              </FlexContainer>
            </FlexContainer>
          </ResponsiveHeadContainer>
          <Spacer gap="0.2rem" />
          <FlexContainer
            padding="10px"
            gap={DefaultBoxGap}
            style={{ overflow: "auto" }}
            height="100%"
          >
            {searched?.map((item) => (
              <FlexContainer
                key={item.fine_tuning_id}
                background={AccentTransparentBlack}
                width="100%"
                padding="1rem"
                breakDirection="row"
                borderRadius={DefaultBorderRadius}
                gap={DefaultBoxGap}
                style={{ flexWrap: "wrap" }}
              >
                <FlexContainer
                  width="fit-content"
                  padding="1rem"
                  breakDirection="row"
                  borderRadius={DefaultBorderRadius}
                  gap={DefaultBoxGap}
                >
                  <FlexContainer alignHorizontal="left" alignVertical="center">
                    <IcoEdit3 size="32px" />
                  </FlexContainer>
                  <FlexContainer
                    breakDirection="column"
                    alignHorizontal="left"
                    alignVertical="center"
                    width="100%"
                    style={{ flexWrap: "wrap" }}
                  >
                    <Heading>{item.fine_tuning_name}</Heading>
                    <Spacer gap="0.1rem" />
                    <Text>
                      {item.description && item.description !== ""
                        ? item.description
                        : "No description for this profile"}
                      {/* t("LabelDefaultProfileDescription") */}
                    </Text>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer
                  gap={DefaultBoxGap}
                  breakDirection="row"
                  style={{ marginLeft: "auto" }}
                >
                  <ToggleButton
                    style={{ margin: "auto" }}
                    onClick={(e: any) => {
                      if (
                        userFineTuning?.fine_tuning_id !== item.fine_tuning_id
                      )
                        handleSetDefaultFineTuning(item.fine_tuning_id);
                    }}
                    toggled={
                      userFineTuning?.fine_tuning_id === item.fine_tuning_id
                    }
                  />
                  <ButtonOutlinedDimmed
                    title={t("LabelEdit")}
                    onClick={() => {
                      setFineTuningIdToEdit(item.fine_tuning_id);
                    }}
                  >
                    <IcoEdit size="16px" margin="auto" />
                  </ButtonOutlinedDimmed>
                  <ButtonOutlinedDimmed
                    title={t("LabelDelete")}
                    onClick={() => {
                      handleRemoveFineTuning(item.fine_tuning_id);
                    }}
                  >
                    <IcoTrash2 size="16px" margin="auto" />
                  </ButtonOutlinedDimmed>
                </FlexContainer>
              </FlexContainer>
            )) ?? (
              <LoaderPaddingWrapper>
                <DotsLoader>
                  <Dot delay="0s" />
                  <Dot delay="0.1s" />
                  <Dot delay="0.2s" />
                </DotsLoader>
              </LoaderPaddingWrapper>
            )}
          </FlexContainer>
        </FlexContainer>
      </ResponsiveBox>
      <CommonModal
        open={Boolean(fineTuningIdToRemove)}
        title="Delete Fine Tuning"
        content="Are you sure to delete fine tuning?"
        callback={removeFineTuneCallback}
        handleClose={() => setFineTuningIdToRemove(undefined)}
      />
      <FineTuneEditModal
        open={Boolean(fineTuningIdToEdit)}
        fine_tuning_id={fineTuningIdToEdit}
        triggerFetch={triggerFetch}
        handleClose={() => {
          setFineTuningIdToEdit(undefined);
        }}
      />
    </PageWrapper>
  );
}

export default FineTune;
