import { deleteApiCall, getApiCall, postApiCall } from ".";
import { PromptForApi } from "../types/prompt";

export const fetchPromptByUserId = (user_id: number, token = "") => {
  const url = `/prompt/fetch/user/${user_id}`;
  return getApiCall(url, token);
};

export const fetchPromptById = (prompt_id: number, token = "") => {
  const url = `/prompt/fetch/${prompt_id}`;
  return getApiCall(url, token);
};

export const savePrompt = (prompt: PromptForApi, token = "") => {
  const url = `/prompt/new`;
  return postApiCall(url, prompt, token);
};

export const updatePrompt = (prompt: PromptForApi, token = "") => {
  const url = `/prompt/update`;
  return postApiCall(url, prompt, token);
};

export const removePrompt = (prompt_id: number, token = "") => {
  const url = `/prompt/${prompt_id}`;
  return deleteApiCall(url, token);
};

export const removeAllPrompt = (token = "") => {
  const url = `/prompt/all`;
  return deleteApiCall(url, token);
};
