import React from "react";
import CodeBlock from "../components/CodeBlock";
import { NeutralGray } from "../theme";

export const textFormatter = (text: string): React.ReactNode => {
  // Split text by code blocks while keeping the delimiters (code blocks)
  const splitText = text.split(/(```\w*\n[\s\S]*?```)/g);

  return (
    <>
      {splitText.map((segment, index) => {
        const codeBlockMatch = segment.match(/```(?:\w*\n)?([\s\S]*?)```/);
        if (codeBlockMatch) {
          const code = codeBlockMatch[1].trim();
          return <CodeBlock key={index} code={code} />;
        } else {
          const processedText = segment
            .replace(/^### (.*$)/gim, "<h3>$1</h3>")
            .replace(/^## (.*$)/gim, "<h2>$1</h2>")
            .replace(/^# (.*$)/gim, "<h1>$1</h1>")
            .replace(/\*\*(.*?)\*\*/gim, "<strong>$1</strong>")
            .replace(/\*(.*?)\*/gim, "<em>$1</em>")
            .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
            .replace(/\[(.*?)\]\((.*?)\)/gim, `<a href='$2' target='_blank' style='font-weight:bold; text-decoration: none; color:${NeutralGray};'>$1</a>`)
            .replace(/\n/g, "<br />")
            .replace(/^> (.*$)/gim, "<blockquote>$1</blockquote>")
            .replace(/^\* (.*$)/gim, "<ul><li>$1</li></ul>")
            .replace(/^\d+\. (.*$)/gim, "<ol><li>$1</li></ol>")
            .replace(/^\|\s*(.*?)\s*\|/gim, (match) => {
              const rows = match.trim().split("\n").map(row => 
                `<tr>${row.replace(/\|\s*(.*?)\s*\|/g, "<td>$1</td>")}</tr>`
              );
              return `<table>${rows.join("")}</table>`;
            });

          return <div key={index} dangerouslySetInnerHTML={{ __html: processedText }} />;
        }
      })}
    </>
  );
};

export default textFormatter;
