import { getApiCall, postApiCall } from ".";
import { BackendEndpoint } from "../constants";
import { LoginType } from "../types/user";

export const userLogin = (payload: LoginType) => {
  const url = "/login";
  return postApiCall(url, payload);
};

export const getUser = (token: string) => {
  const url = "/profile";
  return getApiCall(url, token);
};

export const updateProfiile = (payload: any, token = "") => {
  const url = `/profile/update`;
  return postApiCall(url, payload, token);
};

export const changePassword = (payload: any, token = "") => {
  const url = `/profile/change-password`;
  return postApiCall(url, payload, token);
};
