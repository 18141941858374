export function makeUnique(base = "unique-key") {
  const now = new Date().getTime();
  let random: any = Math.floor(Math.random() * 100000);
  // zero pad random
  random = "" + random;
  while (random.length < 5) {
    random = "0" + random;
  }
  return base + now + random;
}
