import { deleteApiCall, getApiCall, postApiCall } from ".";

export const fetchCustomCommandByUserId = (user_id: number, token = "") => {
  const url = `/custom-command/fetch/user/${user_id}`;
  return getApiCall(url, token);
};

export const fetchCustomCommandById = (
  custom_command_id: number,
  token = ""
) => {
  const url = `/custom-command/fetch/${custom_command_id}`;
  return getApiCall(url, token);
};

export const saveCustomCommand = (fine_tune: any, token = "") => {
  const url = `/custom-command/new`;
  return postApiCall(url, fine_tune, token);
};

export const updateCustomCommand = (fine_tune: any, token = "") => {
  const url = `/custom-command/update`;
  return postApiCall(url, fine_tune, token);
};

export const removeCustomCommand = (custom_command_id: number, token = "") => {
  const url = `/custom-command/${custom_command_id}`;
  return deleteApiCall(url, token);
};
