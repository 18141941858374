import { useState, useEffect, useCallback } from "react";
import loadLabels from "../utils/languages";

// Define the type for labels as a flexible dictionary
type Labels = Record<string, string>;

const useLabels = (
  language: string = "en-uk"
): { labels: Labels | null; t: (key: any) => string } => {
  const [labels, setLabels] = useState<Labels | null>(null);

  const t = useCallback(
    (key: any) => {
      return labels?.[key] ?? key;
    },
    [labels]
  );

  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const langLabels = await loadLabels(language);
        setLabels(langLabels);
      } catch (error) {
        console.error("Failed to load labels:", error);
        // Fallback to default language if there's an error loading the desired language
        try {
          const defaultLabels = await loadLabels("en-uk");
          setLabels(defaultLabels);
        } catch (defaultError) {
          console.error(
            "Failed to load default language labels:",
            defaultError
          );
          setLabels(null);
        }
      }
    };

    fetchLabels();
  }, [language]);

  return { labels, t };
};

export default useLabels;
