import { useCallback, useMemo, useState } from "react";
import {
  ButtonOutlinedDimmed,
  FlexContainer,
  Heading,
  HeadingLarge,
  IcoCommand,
  IcoEdit,
  IcoTrash2,
  PageWrapper,
  ResponsiveBox,
  Spacer,
  Text,
  HideMobile
} from "llane-ui";
import {
  AccentTransparentBlack,
  DefaultBorderRadius,
  DefaultBoxGap,
  PageWrapperMargin,
  ResponsiveHeadContainer
} from "../theme";
import { useTranslate } from "../contexts/SettingContext";
import { useUser } from "../contexts/UserContext";
import { removeCustomCommand } from "../api/customCommandApiCall";
import CommonModal from "../components/Modals/Confirmations/CommonModal";
import NewCustomCommandModal from "../components/Modals/NewCustomCommandModal";
import { usePrompt } from "../contexts/PromptContext";
import CustomCommandsEditModal from "../components/Modals/CustomCommandsEditModal";
import Search from "../components/Search";
import { useSearch } from "../hooks/useSearch";
import Sort from "../components/Sort";
import { SelectList } from "../constants";

export function CustomCommands() {
  const { t } = useTranslate();

  const [selected, setSelected] = useState(SelectList[0]);

  const { customCommands, fetchCustomCommands } = usePrompt();
  const { accessToken } = useUser();

  const search = useSearch();

  const [customCommandIdToRemove, setCustomCommandIdToRemove] = useState<
    number | undefined
  >(undefined);

  const [customCommandIdToEdit, setCustomCommandIdToEdit] = useState<
    number | undefined
  >(undefined);

  const removeCustomCommandCallback = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (customCommandIdToRemove)
        removeCustomCommand(customCommandIdToRemove, accessToken)
          .then(() => {
            fetchCustomCommands();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
    });
  }, [customCommandIdToRemove, fetchCustomCommands, accessToken]);

  const handleRemoveCustomCommand = (custom_command_id: number) => {
    setCustomCommandIdToRemove(custom_command_id);
  };

  const searchedCommands = useMemo(() => {
    if (!customCommands) return undefined;

    let _customCommands = [...customCommands];
    if (search) {
      _customCommands = customCommands?.filter((command) => {
        if (command.command.toLowerCase().includes(search)) return true;
        else if (command.command_return.toLowerCase().includes(search))
          return true;
        else return false;
      });
    }

    if (selected !== "Default") {
      _customCommands.sort((a: any, b: any) => {
        if (selected === "Date, Newest First") {
          const aDate = new Date(a.last_update_date);
          const bDate = new Date(b.last_update_date);

          return aDate > bDate ? -1 : aDate === bDate ? 0 : 1;
        } else if (selected === "Date, Oldest First") {
          const aDate = new Date(a.last_update_date);
          const bDate = new Date(b.last_update_date);

          return aDate > bDate ? 1 : aDate === bDate ? 0 : -1;
        } else if (selected === "Alphabetical, A-Z") {
          return a.command.toLowerCase() > b.command.toLowerCase()
            ? 1
            : a.command.toLowerCase() === b.command.toLowerCase()
            ? 0
            : -1;
        } else if (selected === "Alphabetical, Z-A") {
          return a.command.toLowerCase() > b.command.toLowerCase()
            ? -1
            : a.command.toLowerCase() === b.command.toLowerCase()
            ? 0
            : 1;
        } else return 0;
      });
    }
    return _customCommands;
  }, [customCommands, search, selected]);

  return (
    <PageWrapper margin={PageWrapperMargin}>
      <ResponsiveBox style={{ overflow: "hidden" }} height="auto">
        <FlexContainer
          breakDirection="column"
          gap={DefaultBoxGap}
          style={{ overflow: "hidden" }}
          height="100%"
        >
          <ResponsiveHeadContainer
            breakDirection="row"
            alignHorizontal="center"
            alignVertical="center"
            gap={DefaultBoxGap}
          >
            <FlexContainer style={{ marginRight: "auto" }}>
              <HeadingLarge>{t("LabelCustomCommands")}</HeadingLarge>
            </FlexContainer>
            <FlexContainer
              breakDirection="row"
              alignHorizontal="center"
              style={{ flex: 1, flexWrap: "wrap" }}
              gap={DefaultBoxGap}
              width="100%"
            >
              <FlexContainer style={{ flex: 1 }}>
                <Search />
              </FlexContainer>
              <FlexContainer
                alignHorizontal="center"
                alignVertical="flex-end"
                breakDirection="row"
                style={{ marginLeft: "auto" }}
              >
                <Sort selected={selected} setSelected={setSelected} />
                <NewCustomCommandModal />
              </FlexContainer>
            </FlexContainer>
          </ResponsiveHeadContainer>

          <Spacer gap="0.2rem" />
          {(!searchedCommands || searchedCommands.length === 0) && (
            <>
              <Text>{t("LabelCustomCommandsEmpty")}</Text>
            </>
          )}
          <FlexContainer
            padding="10px"
            gap={DefaultBoxGap}
            style={{ overflow: "auto" }}
            height="100%"
          >
            {searchedCommands?.map((customCommand: any, index: number) => (
              <FlexContainer
                background={AccentTransparentBlack}
                width="100%"
                padding="1rem"
                breakDirection="auto"
                borderRadius={DefaultBorderRadius}
                gap={DefaultBoxGap}
                key={customCommand.custom_command_id ?? index}
              >
                <FlexContainer alignHorizontal="left" alignVertical="center">
                  <IcoCommand size="32px" />
                </FlexContainer>
                <FlexContainer
                  breakDirection="column"
                  alignHorizontal="left"
                  alignVertical="center"
                  width="100%"
                >
                  <Heading>/{customCommand?.command}</Heading>
                  <Spacer gap="0.1rem" />
                  <HideMobile>
                    <Text>{customCommand?.command_return}</Text>
                  </HideMobile>
                </FlexContainer>
                <FlexContainer>
                  <ButtonOutlinedDimmed
                    title={t("LabelEdit")}
                    onClick={() => {
                      setCustomCommandIdToEdit(customCommand.custom_command_id);
                    }}
                  >
                    <IcoEdit size="16px" margin="auto" />
                  </ButtonOutlinedDimmed>
                </FlexContainer>
                <FlexContainer>
                  <ButtonOutlinedDimmed
                    title={t("LabelDelete")}
                    onClick={() => {
                      handleRemoveCustomCommand(
                        customCommand.custom_command_id
                      );
                    }}
                  >
                    <IcoTrash2 size="16px" margin="auto" />
                  </ButtonOutlinedDimmed>
                </FlexContainer>
              </FlexContainer>
            ))}
          </FlexContainer>
        </FlexContainer>
      </ResponsiveBox>
      <CommonModal
        open={Boolean(customCommandIdToRemove)}
        title="Delete Custom Command"
        content="Are you sure to delete custom command?"
        callback={removeCustomCommandCallback}
        handleClose={() => setCustomCommandIdToRemove(undefined)}
      />
      <CustomCommandsEditModal
        open={Boolean(customCommandIdToEdit)}
        custom_command_id={customCommandIdToEdit}
        handleClose={() => {
          setCustomCommandIdToEdit(undefined);
        }}
        callback={fetchCustomCommands}
      />
    </PageWrapper>
  );
}

export default CustomCommands;
