// Colours
export const AccentTransparentBlack = `rgba(0, 0, 0, 0.05)`
export const DividerGray = `rgba(100, 100, 100, 0.15)`
export const PromptTextBorder = `rgba(100, 100, 100, 0.5)`
export const NeutralGray = `rgba(150, 150, 150, 1)`
export const BubbleBackgroundUser = `#0B7EF5`;
export const BubbleBackgroundAi = `rgba(150, 150, 150, 0.3)`;
export const PrimaryFontColor = `rgba(230, 230, 250, 1)`;
export const AccentColorPressed = `rgba(0, 87, 207, 0.95)`;
export const DarkGreyColor = `#111`;
export const PrimaryColorDimmed = `rgba(255, 255, 255, 0.01)`;

// Layout
export const PageWrapperMargin = `10px`
export const DefaultBorderRadius = `30px`
export const DefaultBoxGap = `10px`
export const LargeBoxGap = `20px`
export const LargeButtonPadding = `20px 30px`