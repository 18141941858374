export const handleLoadPrompt = (
  event: React.ChangeEvent<HTMLInputElement>,
  callback: Function
) => {
  const fileReader = new FileReader();

  fileReader.onload = (e) => {
    const content = e.target?.result;
    if (content) {
      try {
        const messages = JSON.parse(content as string);
        callback(messages);
      } catch (error) {
        console.error("Failed to load and parse the file", error);
      }
    }
  };

  if (event.target.files?.[0]) {
    fileReader.readAsText(event.target.files[0]);
  }
};
