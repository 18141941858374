import { getApiCall, postApiCall, postApiCallWithStream } from ".";

export const sendMessageToAi = (
  model: string,
  payload: any,
  token = "",
  signal?: AbortSignal | null
) => {
  const url = `/ai/prompt/${model}`;
  return postApiCall(url, payload, token, signal);
};

export const sendMessageToAiWithStream = (
  model: string,
  payload: any,
  callback = (str: string) => {},
  token = "",
  signal?: AbortSignal | null
) => {
  const url = `/ai/prompt/extra/${model}`;
  return postApiCallWithStream(url, payload, callback, token, signal);
};

export const summarisePrompt = (payload: any, token = "") => {
  const url = "/ai/prompt/summary";
  return postApiCall(url, payload, token);
};

export const fetchSuggestions = (
  limit: number,
  language = "en-uk",
  token = ""
) => {
  const url = `/ai/prompt/suggestions?limit=${limit}&language=${language}`;
  return getApiCall(url, token);
};
