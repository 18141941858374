export const pasteFromClipboard = () => {
  return navigator.clipboard
    .readText()
    .then((text) => {
      return text;
    })
    .catch((err) => {
      console.error("Failed to read clipboard contents: ", err);
      return null;
    });
};

export const copyToClipboard = (text: string) => {
  return navigator.clipboard.writeText(text);
};
