import { useCallback, useEffect, useState } from "react";
import { Prompt } from "../types/prompt";
import { fetchPromptByUserId } from "../api/promptApiCall";
import { useUser } from "../contexts/UserContext";

export const useSavedPrompts = () => {
  const [prompts, setPrompts] = useState<Prompt[] | undefined>();
  const { user, accessToken } = useUser();

  const triggerFetch = useCallback(() => {
    if (user && user.user_id) {
      fetchPromptByUserId(user.user_id, accessToken)
        .then((data) => {
          if (data && Array.isArray(data)) {
            const prompts: Prompt[] = data
              .map((_prompt) => {
                try {
                  if (_prompt) {
                    const {
                      prompt_prompt_id,
                      prompt_title,
                      prompt_description,
                      prompt_user_id,
                      prompt_prompt,
                      prompt_organisation_id,
                      prompt_llm_engine_id,
                      prompt_fine_tuning_id,
                      prompt_creation_date,
                      prompt_last_prompt_date
                    } = _prompt;
                    const prompt: Prompt = {
                      prompt_id: Number(prompt_prompt_id),
                      title: prompt_title,
                      description: prompt_description,
                      user_id: Number(prompt_user_id),
                      organisation_id: Number(prompt_organisation_id),
                      llm_engine_id: Number(prompt_llm_engine_id),
                      fine_tuning_id: Number(prompt_fine_tuning_id),
                      prompt: JSON.parse(prompt_prompt),
                      creation_date: prompt_creation_date,
                      last_prompt_date: prompt_last_prompt_date
                    };
                    return prompt;
                  } else {
                    return null;
                  }
                } catch {
                  return null;
                }
              })
              .flatMap((f) => (f ? [f] : []));

            setPrompts(prompts);
          } else {
            setPrompts([]);
          }
        })
        .catch(() => {
          setPrompts([]);
        });
    }
  }, [user, setPrompts, accessToken]);

  useEffect(() => {
    triggerFetch();
  }, [triggerFetch]);

  return { prompts, triggerFetch };
};
