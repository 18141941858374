import {
  Box,
  ButtonOutlinedDimmed,
  Divider,
  FlexContainer,
  Heading,
  IcoCloud,
  IcoFolder,
  IcoX,
  InputOutlined,
  LinkWrapper,
  Modal,
  Spacer,
  Text,
  TextAreaOutlined
} from "llane-ui";
import {
  DefaultBorderRadius,
  DefaultBoxGap,
  Dot,
  DotsLoader,
  LoaderPaddingWrapper
} from "../../../theme";
import { useModal } from "../../../contexts/ModalContext";
import { usePrompt } from "../../../contexts/PromptContext";
import { handleSavePrompt } from "../../../utils/saveToFile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { savePrompt } from "../../../api/promptApiCall";
import { PromptForApi } from "../../../types/prompt";
import { useTranslate } from "../../../contexts/SettingContext";
import { sendMessageToAi } from "../../../api/aiApiCall";
import {
  PromptDescription,
  PromptTitle
} from "../../../constants/preFormattedRequests";

const SavePromptModal = () => {
  const { isOpen, handleClose } = useModal("savePrompt");
  const { messages, setPromptId } = usePrompt();
  const { user, accessToken } = useUser();
  const { t } = useTranslate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [savingToCloud, setSavingToCloud] = useState(false);

  const handleSave = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleSavePrompt(messages);
      setTitle("");
      setDescription("");
      handleClose();
    },
    [messages, handleClose]
  );

  const handleSaveToCloud = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setSavingToCloud(true);
      const promptData: PromptForApi = {
        user_id: user.user_id,
        title,
        description,
        organisation_id: 1,
        llm_engine_id: 1,
        fine_tuning_id: 1,
        prompt: JSON.stringify(messages)
      };

      savePrompt(promptData, accessToken)
        .then((response) => {
          if (response.result && response.prompt_id) {
            setPromptId(response.prompt_id);
            // add success logic here . ex. show success alert
          }
          setSavingToCloud(false);
        })
        .catch(() => {
          setSavingToCloud(false);
        })
        .finally(() => {
          setTitle("");
          setDescription("");
          handleClose();
        });
    },
    [messages, handleClose, user, accessToken, setPromptId, title, description]
  );

  useEffect(() => {
    if (isOpen && messages?.length) {
      const payload = {
        context: messages,
        content: PromptTitle
      };
      sendMessageToAi("GPT35", payload, accessToken)
        .then((response) => {
          if (response) {
            if (response.choices?.[0]?.message?.content) {
              const title = response.choices[0].message.content;
              setTitle(title);
            }
            // add success logic here . ex. show success alert
          }
        })
        .catch(() => {});
      const desciptionPayload = {
        context: messages,
        content: PromptDescription
      };
      sendMessageToAi("GPT35", desciptionPayload, accessToken)
        .then((response) => {
          if (response) {
            if (response.choices?.[0]?.message?.content) {
              const title = response.choices[0].message.content;
              setDescription(title);
            }
            // add success logic here . ex. show success alert
          }
        })
        .catch(() => {});
    }
  }, [isOpen, messages, accessToken]);

  const loading = useMemo(() => {
    if (title === "" || description === "") return true;
    else return false;
  }, [title, description]);

  return (
    <Modal
      width="auto"
      open={isOpen}
      handleClose={() => {
        setTitle("");
        setDescription("");
        handleClose();
      }}
    >
      <Box height="auto" maxHeight="50vh" width="auto" minWidth="40vw" overflow="hidden">
        <FlexContainer alignVertical="space-between" breakDirection="row">
          <Heading>{t("LabelSavePrompt")}</Heading>
          <LinkWrapper
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              setTitle("");
              setDescription("");
              handleClose();
            }}
          >
            <Box padding="0">
              <IcoX margin="0" />
            </Box>
          </LinkWrapper>
        </FlexContainer>
        <Divider gap="3px" />
        <>
          {loading ? (
            <LoaderPaddingWrapper>
              <DotsLoader>
                <Dot delay="0s" />
                <Dot delay="0.1s" />
                <Dot delay="0.2s" />
              </DotsLoader>
            </LoaderPaddingWrapper>
          ) : (
            <>
              <FlexContainer>
                <Text style={{ lineHeight: "1.5em" }}>
                  {t("LabelSavePromptConfirmation")}
                </Text>
              </FlexContainer>
              <FlexContainer
                gap={DefaultBoxGap}
                breakDirection="row"
                alignVertical="center"
                alignHorizontal="center"
              >
                <ButtonOutlinedDimmed
                  margin="0"
                  borderRadius={DefaultBorderRadius}
                  onClick={(e) => handleSaveToCloud(e)}
                >
                  {savingToCloud ? (
                    <DotsLoader>
                      <Dot delay="0s" />
                      <Dot delay="0.1s" />
                      <Dot delay="0.2s" />
                    </DotsLoader>
                  ) : (
                    <>
                      <IcoCloud margin="auto 10px -3px auto" size="16px" />
                      {t("LabelSaveToCloud")}
                    </>
                  )}
                </ButtonOutlinedDimmed>
                <ButtonOutlinedDimmed
                  margin="0"
                  borderRadius={DefaultBorderRadius}
                  onClick={(e) => handleSave(e)}
                >
                  <IcoFolder margin="auto 10px -3px auto" size="16px" />
                  {t("LabelSaveToFile")}
                </ButtonOutlinedDimmed>
              </FlexContainer>
            </>
          )}
        </>
      </Box>
    </Modal>
  );
};

export default SavePromptModal;
