import styled from 'styled-components';

import { ReactComponent as Activity } from './library/activity.svg';
import { ReactComponent as Airplay } from './library/airplay.svg';
import { ReactComponent as AlertCircle } from './library/alert-circle.svg';
import { ReactComponent as AlertOctagon } from './library/alert-octagon.svg';
import { ReactComponent as AlertTriangle } from './library/alert-triangle.svg';
import { ReactComponent as AlignCenter } from './library/align-center.svg';
import { ReactComponent as AlignJustify } from './library/align-justify.svg';
import { ReactComponent as AlignLeft } from './library/align-left.svg';
import { ReactComponent as AlignRight } from './library/align-right.svg';
import { ReactComponent as Anchor } from './library/anchor.svg';
import { ReactComponent as Aperture } from './library/aperture.svg';
import { ReactComponent as Archive } from './library/archive.svg';
import { ReactComponent as ArrowDownCircle } from './library/arrow-down-circle.svg';
import { ReactComponent as ArrowDownLeft } from './library/arrow-down-left.svg';
import { ReactComponent as ArrowDownRight } from './library/arrow-down-right.svg';
import { ReactComponent as ArrowDown } from './library/arrow-down.svg';
import { ReactComponent as ArrowLeftCircle } from './library/arrow-left-circle.svg';
import { ReactComponent as ArrowLeft } from './library/arrow-left.svg';
import { ReactComponent as ArrowRightCircle } from './library/arrow-right-circle.svg';
import { ReactComponent as ArrowRight } from './library/arrow-right.svg';
import { ReactComponent as ArrowUpCircle } from './library/arrow-up-circle.svg';
import { ReactComponent as ArrowUpLeft } from './library/arrow-up-left.svg';
import { ReactComponent as ArrowUpRight } from './library/arrow-up-right.svg';
import { ReactComponent as ArrowUp } from './library/arrow-up.svg';
import { ReactComponent as AtSign } from './library/at-sign.svg';
import { ReactComponent as Award } from './library/award.svg';
import { ReactComponent as BarChart2 } from './library/bar-chart-2.svg';
import { ReactComponent as BarChart } from './library/bar-chart.svg';
import { ReactComponent as BatteryCharging } from './library/battery-charging.svg';
import { ReactComponent as Battery } from './library/battery.svg';
import { ReactComponent as BellOff } from './library/bell-off.svg';
import { ReactComponent as Bell } from './library/bell.svg';
import { ReactComponent as Bluetooth } from './library/bluetooth.svg';
import { ReactComponent as Bold } from './library/bold.svg';
import { ReactComponent as BookOpen } from './library/book-open.svg';
import { ReactComponent as Book } from './library/book.svg';
import { ReactComponent as Bookmark } from './library/bookmark.svg';
import { ReactComponent as Box } from './library/box.svg';
import { ReactComponent as Briefcase } from './library/briefcase.svg';
import { ReactComponent as Calendar } from './library/calendar.svg';
import { ReactComponent as CameraOff } from './library/camera-off.svg';
import { ReactComponent as Camera } from './library/camera.svg';
import { ReactComponent as Cast } from './library/cast.svg';
import { ReactComponent as CheckCircle } from './library/check-circle.svg';
import { ReactComponent as CheckSquare } from './library/check-square.svg';
import { ReactComponent as Check } from './library/check.svg';
import { ReactComponent as ChevronDown } from './library/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './library/chevron-left.svg';
import { ReactComponent as ChevronRight } from './library/chevron-right.svg';
import { ReactComponent as ChevronUp } from './library/chevron-up.svg';
import { ReactComponent as ChevronsDown } from './library/chevrons-down.svg';
import { ReactComponent as ChevronsLeft } from './library/chevrons-left.svg';
import { ReactComponent as ChevronsRight } from './library/chevrons-right.svg';
import { ReactComponent as ChevronsUp } from './library/chevrons-up.svg';
import { ReactComponent as Chrome } from './library/chrome.svg';
import { ReactComponent as Circle } from './library/circle.svg';
import { ReactComponent as Clipboard } from './library/clipboard.svg';
import { ReactComponent as Clock } from './library/clock.svg';
import { ReactComponent as CloudDrizzle } from './library/cloud-drizzle.svg';
import { ReactComponent as CloudLightning } from './library/cloud-lightning.svg';
import { ReactComponent as CloudOff } from './library/cloud-off.svg';
import { ReactComponent as CloudRain } from './library/cloud-rain.svg';
import { ReactComponent as CloudSnow } from './library/cloud-snow.svg';
import { ReactComponent as Cloud } from './library/cloud.svg';
import { ReactComponent as Code } from './library/code.svg';
import { ReactComponent as Codepen } from './library/codepen.svg';
import { ReactComponent as Codesandbox } from './library/codesandbox.svg';
import { ReactComponent as Coffee } from './library/coffee.svg';
import { ReactComponent as Columns } from './library/columns.svg';
import { ReactComponent as Command } from './library/command.svg';
import { ReactComponent as Compass } from './library/compass.svg';
import { ReactComponent as Copy } from './library/copy.svg';
import { ReactComponent as CornerDownLeft } from './library/corner-down-left.svg';
import { ReactComponent as CornerDownRight } from './library/corner-down-right.svg';
import { ReactComponent as CornerLeftDown } from './library/corner-left-down.svg';
import { ReactComponent as CornerLeftUp } from './library/corner-left-up.svg';
import { ReactComponent as CornerRightDown } from './library/corner-right-down.svg';
import { ReactComponent as CornerRightUp } from './library/corner-right-up.svg';
import { ReactComponent as CornerUpLeft } from './library/corner-up-left.svg';
import { ReactComponent as CornerUpRight } from './library/corner-up-right.svg';
import { ReactComponent as Cpu } from './library/cpu.svg';
import { ReactComponent as CreditCard } from './library/credit-card.svg';
import { ReactComponent as Crop } from './library/crop.svg';
import { ReactComponent as Crosshair } from './library/crosshair.svg';
import { ReactComponent as Database } from './library/database.svg';
import { ReactComponent as Delete } from './library/delete.svg';
import { ReactComponent as Disc } from './library/disc.svg';
import { ReactComponent as DivideCircle } from './library/divide-circle.svg';
import { ReactComponent as DivideSquare } from './library/divide-square.svg';
import { ReactComponent as Divide } from './library/divide.svg';
import { ReactComponent as DollarSign } from './library/dollar-sign.svg';
import { ReactComponent as DownloadCloud } from './library/download-cloud.svg';
import { ReactComponent as Download } from './library/download.svg';
import { ReactComponent as Dribbble } from './library/dribbble.svg';
import { ReactComponent as Droplet } from './library/droplet.svg';
import { ReactComponent as Edit2 } from './library/edit-2.svg';
import { ReactComponent as Edit3 } from './library/edit-3.svg';
import { ReactComponent as Edit } from './library/edit.svg';
import { ReactComponent as ExternalLink } from './library/external-link.svg';
import { ReactComponent as EyeOff } from './library/eye-off.svg';
import { ReactComponent as Eye } from './library/eye.svg';
import { ReactComponent as Facebook } from './library/facebook.svg';
import { ReactComponent as FastForward } from './library/fast-forward.svg';
import { ReactComponent as Feather } from './library/feather.svg';
import { ReactComponent as Figma } from './library/figma.svg';
import { ReactComponent as FileMinus } from './library/file-minus.svg';
import { ReactComponent as FilePlus } from './library/file-plus.svg';
import { ReactComponent as FileText } from './library/file-text.svg';
import { ReactComponent as File } from './library/file.svg';
import { ReactComponent as Film } from './library/film.svg';
import { ReactComponent as Filter } from './library/filter.svg';
import { ReactComponent as Flag } from './library/flag.svg';
import { ReactComponent as FolderMinus } from './library/folder-minus.svg';
import { ReactComponent as FolderPlus } from './library/folder-plus.svg';
import { ReactComponent as Folder } from './library/folder.svg';
import { ReactComponent as Framer } from './library/framer.svg';
import { ReactComponent as Frown } from './library/frown.svg';
import { ReactComponent as Gift } from './library/gift.svg';
import { ReactComponent as GitBranch } from './library/git-branch.svg';
import { ReactComponent as GitCommit } from './library/git-commit.svg';
import { ReactComponent as GitMerge } from './library/git-merge.svg';
import { ReactComponent as GitPullRequest } from './library/git-pull-request.svg';
import { ReactComponent as Github } from './library/github.svg';
import { ReactComponent as Gitlab } from './library/gitlab.svg';
import { ReactComponent as Globe } from './library/globe.svg';
import { ReactComponent as Grid } from './library/grid.svg';
import { ReactComponent as HardDrive } from './library/hard-drive.svg';
import { ReactComponent as Hash } from './library/hash.svg';
import { ReactComponent as Headphones } from './library/headphones.svg';
import { ReactComponent as Heart } from './library/heart.svg';
import { ReactComponent as HelpCircle } from './library/help-circle.svg';
import { ReactComponent as Hexagon } from './library/hexagon.svg';
import { ReactComponent as Home } from './library/home.svg';
import { ReactComponent as Image } from './library/image.svg';
import { ReactComponent as Inbox } from './library/inbox.svg';
import { ReactComponent as Info } from './library/info.svg';
import { ReactComponent as Instagram } from './library/instagram.svg';
import { ReactComponent as Italic } from './library/italic.svg';
import { ReactComponent as Key } from './library/key.svg';
import { ReactComponent as Layers } from './library/layers.svg';
import { ReactComponent as Layout } from './library/layout.svg';
import { ReactComponent as LifeBuoy } from './library/life-buoy.svg';
import { ReactComponent as Link2 } from './library/link-2.svg';
import { ReactComponent as Link } from './library/link.svg';
import { ReactComponent as Linkedin } from './library/linkedin.svg';
import { ReactComponent as List } from './library/list.svg';
import { ReactComponent as Loader } from './library/loader.svg';
import { ReactComponent as Lock } from './library/lock.svg';
import { ReactComponent as LogIn } from './library/log-in.svg';
import { ReactComponent as LogOut } from './library/log-out.svg';
import { ReactComponent as Mail } from './library/mail.svg';
import { ReactComponent as MapPin } from './library/map-pin.svg';
import { ReactComponent as Map } from './library/map.svg';
import { ReactComponent as Maximize2 } from './library/maximize-2.svg';
import { ReactComponent as Maximize } from './library/maximize.svg';
import { ReactComponent as Meh } from './library/meh.svg';
import { ReactComponent as Menu } from './library/menu.svg';
import { ReactComponent as MessageCircle } from './library/message-circle.svg';
import { ReactComponent as MessageSquare } from './library/message-square.svg';
import { ReactComponent as MicOff } from './library/mic-off.svg';
import { ReactComponent as Mic } from './library/mic.svg';
import { ReactComponent as Minimize2 } from './library/minimize-2.svg';
import { ReactComponent as Minimize } from './library/minimize.svg';
import { ReactComponent as MinusCircle } from './library/minus-circle.svg';
import { ReactComponent as MinusSquare } from './library/minus-square.svg';
import { ReactComponent as Minus } from './library/minus.svg';
import { ReactComponent as Monitor } from './library/monitor.svg';
import { ReactComponent as Moon } from './library/moon.svg';
import { ReactComponent as MoreHorizontal } from './library/more-horizontal.svg';
import { ReactComponent as MoreVertical } from './library/more-vertical.svg';
import { ReactComponent as MousePointer } from './library/mouse-pointer.svg';
import { ReactComponent as Move } from './library/move.svg';
import { ReactComponent as Music } from './library/music.svg';
import { ReactComponent as Navigation2 } from './library/navigation-2.svg';
import { ReactComponent as Navigation } from './library/navigation.svg';
import { ReactComponent as Octagon } from './library/octagon.svg';
import { ReactComponent as Package } from './library/package.svg';
import { ReactComponent as Paperclip } from './library/paperclip.svg';
import { ReactComponent as PauseCircle } from './library/pause-circle.svg';
import { ReactComponent as Pause } from './library/pause.svg';
import { ReactComponent as PenTool } from './library/pen-tool.svg';
import { ReactComponent as Percent } from './library/percent.svg';
import { ReactComponent as PhoneCall } from './library/phone-call.svg';
import { ReactComponent as PhoneForwarded } from './library/phone-forwarded.svg';
import { ReactComponent as PhoneIncoming } from './library/phone-incoming.svg';
import { ReactComponent as PhoneMissed } from './library/phone-missed.svg';
import { ReactComponent as PhoneOff } from './library/phone-off.svg';
import { ReactComponent as PhoneOutgoing } from './library/phone-outgoing.svg';
import { ReactComponent as Phone } from './library/phone.svg';
import { ReactComponent as PieChart } from './library/pie-chart.svg';
import { ReactComponent as PlayCircle } from './library/play-circle.svg';
import { ReactComponent as Play } from './library/play.svg';
import { ReactComponent as PlusCircle } from './library/plus-circle.svg';
import { ReactComponent as PlusSquare } from './library/plus-square.svg';
import { ReactComponent as Plus } from './library/plus.svg';
import { ReactComponent as Pocket } from './library/pocket.svg';
import { ReactComponent as Power } from './library/power.svg';
import { ReactComponent as Printer } from './library/printer.svg';
import { ReactComponent as Radio } from './library/radio.svg';
import { ReactComponent as RefreshCcw } from './library/refresh-ccw.svg';
import { ReactComponent as RefreshCw } from './library/refresh-cw.svg';
import { ReactComponent as Repeat } from './library/repeat.svg';
import { ReactComponent as Rewind } from './library/rewind.svg';
import { ReactComponent as RotateCcw } from './library/rotate-ccw.svg';
import { ReactComponent as RotateCw } from './library/rotate-cw.svg';
import { ReactComponent as Rss } from './library/rss.svg';
import { ReactComponent as Save } from './library/save.svg';
import { ReactComponent as Scissors } from './library/scissors.svg';
import { ReactComponent as Search } from './library/search.svg';
import { ReactComponent as Send } from './library/send.svg';
import { ReactComponent as Server } from './library/server.svg';
import { ReactComponent as Settings } from './library/settings.svg';
import { ReactComponent as Share2 } from './library/share-2.svg';
import { ReactComponent as Share } from './library/share.svg';
import { ReactComponent as ShieldOff } from './library/shield-off.svg';
import { ReactComponent as Shield } from './library/shield.svg';
import { ReactComponent as ShoppingBag } from './library/shopping-bag.svg';
import { ReactComponent as ShoppingCart } from './library/shopping-cart.svg';
import { ReactComponent as Shuffle } from './library/shuffle.svg';
import { ReactComponent as Sidebar } from './library/sidebar.svg';
import { ReactComponent as SkipBack } from './library/skip-back.svg';
import { ReactComponent as SkipForward } from './library/skip-forward.svg';
import { ReactComponent as Slack } from './library/slack.svg';
import { ReactComponent as Slash } from './library/slash.svg';
import { ReactComponent as Sliders } from './library/sliders.svg';
import { ReactComponent as Smartphone } from './library/smartphone.svg';
import { ReactComponent as Smile } from './library/smile.svg';
import { ReactComponent as Speaker } from './library/speaker.svg';
import { ReactComponent as Square } from './library/square.svg';
import { ReactComponent as Star } from './library/star.svg';
import { ReactComponent as StopCircle } from './library/stop-circle.svg';
import { ReactComponent as Sun } from './library/sun.svg';
import { ReactComponent as Sunrise } from './library/sunrise.svg';
import { ReactComponent as Sunset } from './library/sunset.svg';
import { ReactComponent as Table } from './library/table.svg';
import { ReactComponent as Tablet } from './library/tablet.svg';
import { ReactComponent as Tag } from './library/tag.svg';
import { ReactComponent as Target } from './library/target.svg';
import { ReactComponent as Terminal } from './library/terminal.svg';
import { ReactComponent as Thermometer } from './library/thermometer.svg';
import { ReactComponent as ThumbsDown } from './library/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from './library/thumbs-up.svg';
import { ReactComponent as ToggleLeft } from './library/toggle-left.svg';
import { ReactComponent as ToggleRight } from './library/toggle-right.svg';
import { ReactComponent as Tool } from './library/tool.svg';
import { ReactComponent as Trash2 } from './library/trash-2.svg';
import { ReactComponent as Trash } from './library/trash.svg';
import { ReactComponent as Trello } from './library/trello.svg';
import { ReactComponent as TrendingDown } from './library/trending-down.svg';
import { ReactComponent as TrendingUp } from './library/trending-up.svg';
import { ReactComponent as Triangle } from './library/triangle.svg';
import { ReactComponent as Truck } from './library/truck.svg';
import { ReactComponent as Tv } from './library/tv.svg';
import { ReactComponent as Twitch } from './library/twitch.svg';
import { ReactComponent as Twitter } from './library/twitter.svg';
import { ReactComponent as Type } from './library/type.svg';
import { ReactComponent as Umbrella } from './library/umbrella.svg';
import { ReactComponent as Underline } from './library/underline.svg';
import { ReactComponent as Unlock } from './library/unlock.svg';
import { ReactComponent as UploadCloud } from './library/upload-cloud.svg';
import { ReactComponent as Upload } from './library/upload.svg';
import { ReactComponent as UserCheck } from './library/user-check.svg';
import { ReactComponent as UserMinus } from './library/user-minus.svg';
import { ReactComponent as UserPlus } from './library/user-plus.svg';
import { ReactComponent as UserX } from './library/user-x.svg';
import { ReactComponent as User } from './library/user.svg';
import { ReactComponent as Users } from './library/users.svg';
import { ReactComponent as VideoOff } from './library/video-off.svg';
import { ReactComponent as Video } from './library/video.svg';
import { ReactComponent as Voicemail } from './library/voicemail.svg';
import { ReactComponent as Volume1 } from './library/volume-1.svg';
import { ReactComponent as Volume2 } from './library/volume-2.svg';
import { ReactComponent as VolumeX } from './library/volume-x.svg';
import { ReactComponent as Volume } from './library/volume.svg';
import { ReactComponent as Watch } from './library/watch.svg';
import { ReactComponent as WifiOff } from './library/wifi-off.svg';
import { ReactComponent as Wifi } from './library/wifi.svg';
import { ReactComponent as Wind } from './library/wind.svg';
import { ReactComponent as XCircle } from './library/x-circle.svg';
import { ReactComponent as XOctagon } from './library/x-octagon.svg';
import { ReactComponent as XSquare } from './library/x-square.svg';
import { ReactComponent as X } from './library/x.svg';
import { ReactComponent as Youtube } from './library/youtube.svg';
import { ReactComponent as ZapOff } from './library/zap-off.svg';
import { ReactComponent as Zap } from './library/zap.svg';
import { ReactComponent as ZoomIn } from './library/zoom-in.svg';
import { ReactComponent as ZoomOut } from './library/zoom-out.svg';

interface IconProps {
  size?: string;
  margin?: string;
}

export const IcoActivity = styled(Activity)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAirplay = styled(Airplay)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlertCircle = styled(AlertCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlertOctagon = styled(AlertOctagon)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlertTriangle = styled(AlertTriangle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlignCenter = styled(AlignCenter)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlignJustify = styled(AlignJustify)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlignLeft = styled(AlignLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAlignRight = styled(AlignRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAnchor = styled(Anchor)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAperture = styled(Aperture)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArchive = styled(Archive)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowDownCircle = styled(ArrowDownCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowDownLeft = styled(ArrowDownLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowDownRight = styled(ArrowDownRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowDown = styled(ArrowDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowLeftCircle = styled(ArrowLeftCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowLeft = styled(ArrowLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowRightCircle = styled(ArrowRightCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowRight = styled(ArrowRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowUpCircle = styled(ArrowUpCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowUpLeft = styled(ArrowUpLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowUpRight = styled(ArrowUpRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoArrowUp = styled(ArrowUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAtSign = styled(AtSign)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoAward = styled(Award)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBarChart2 = styled(BarChart2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBarChart = styled(BarChart)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBatteryCharging = styled(BatteryCharging)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBattery = styled(Battery)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBellOff = styled(BellOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBell = styled(Bell)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBluetooth = styled(Bluetooth)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBold = styled(Bold)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBookOpen = styled(BookOpen)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBook = styled(Book)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBookmark = styled(Bookmark)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBox = styled(Box)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoBriefcase = styled(Briefcase)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCalendar = styled(Calendar)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCameraOff = styled(CameraOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCamera = styled(Camera)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCast = styled(Cast)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCheckCircle = styled(CheckCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCheckSquare = styled(CheckSquare)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCheck = styled(Check)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronDown = styled(ChevronDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronLeft = styled(ChevronLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronRight = styled(ChevronRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronUp = styled(ChevronUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronsDown = styled(ChevronsDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronsLeft = styled(ChevronsLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronsRight = styled(ChevronsRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChevronsUp = styled(ChevronsUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoChrome = styled(Chrome)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCircle = styled(Circle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoClipboard = styled(Clipboard)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoClock = styled(Clock)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCloudDrizzle = styled(CloudDrizzle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCloudLightning = styled(CloudLightning)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCloudOff = styled(CloudOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCloudRain = styled(CloudRain)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCloudSnow = styled(CloudSnow)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCloud = styled(Cloud)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCode = styled(Code)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCodepen = styled(Codepen)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCodesandbox = styled(Codesandbox)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCoffee = styled(Coffee)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoColumns = styled(Columns)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCommand = styled(Command)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCompass = styled(Compass)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCopy = styled(Copy)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerDownLeft = styled(CornerDownLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerDownRight = styled(CornerDownRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerLeftDown = styled(CornerLeftDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerLeftUp = styled(CornerLeftUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerRightDown = styled(CornerRightDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerRightUp = styled(CornerRightUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerUpLeft = styled(CornerUpLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCornerUpRight = styled(CornerUpRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCpu = styled(Cpu)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCreditCard = styled(CreditCard)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCrop = styled(Crop)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoCrosshair = styled(Crosshair)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDatabase = styled(Database)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDelete = styled(Delete)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDisc = styled(Disc)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDivideCircle = styled(DivideCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDivideSquare = styled(DivideSquare)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDivide = styled(Divide)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDollarSign = styled(DollarSign)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDownloadCloud = styled(DownloadCloud)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDownload = styled(Download)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDribbble = styled(Dribbble)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoDroplet = styled(Droplet)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoEdit2 = styled(Edit2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoEdit3 = styled(Edit3)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoEdit = styled(Edit)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoExternalLink = styled(ExternalLink)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoEyeOff = styled(EyeOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoEye = styled(Eye)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFacebook = styled(Facebook)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFastForward = styled(FastForward)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFeather = styled(Feather)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFigma = styled(Figma)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFileMinus = styled(FileMinus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFilePlus = styled(FilePlus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFileText = styled(FileText)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFile = styled(File)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFilm = styled(Film)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFilter = styled(Filter)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFlag = styled(Flag)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFolderMinus = styled(FolderMinus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFolderPlus = styled(FolderPlus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFolder = styled(Folder)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFramer = styled(Framer)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoFrown = styled(Frown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGift = styled(Gift)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGitBranch = styled(GitBranch)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGitCommit = styled(GitCommit)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGitMerge = styled(GitMerge)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGitPullRequest = styled(GitPullRequest)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGithub = styled(Github)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGitlab = styled(Gitlab)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGlobe = styled(Globe)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoGrid = styled(Grid)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHardDrive = styled(HardDrive)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHash = styled(Hash)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHeadphones = styled(Headphones)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHeart = styled(Heart)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHelpCircle = styled(HelpCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHexagon = styled(Hexagon)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoHome = styled(Home)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoImage = styled(Image)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoInbox = styled(Inbox)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoInfo = styled(Info)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoInstagram = styled(Instagram)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoItalic = styled(Italic)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoKey = styled(Key)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLayers = styled(Layers)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLayout = styled(Layout)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLifeBuoy = styled(LifeBuoy)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLink2 = styled(Link2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLink = styled(Link)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLinkedin = styled(Linkedin)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoList = styled(List)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLoader = styled(Loader)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLock = styled(Lock)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLogIn = styled(LogIn)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoLogOut = styled(LogOut)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMail = styled(Mail)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMapPin = styled(MapPin)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMap = styled(Map)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMaximize2 = styled(Maximize2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMaximize = styled(Maximize)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMeh = styled(Meh)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMenu = styled(Menu)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMessageCircle = styled(MessageCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMessageSquare = styled(MessageSquare)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMicOff = styled(MicOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMic = styled(Mic)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMinimize2 = styled(Minimize2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMinimize = styled(Minimize)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMinusCircle = styled(MinusCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMinusSquare = styled(MinusSquare)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMinus = styled(Minus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMonitor = styled(Monitor)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMoon = styled(Moon)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMoreHorizontal = styled(MoreHorizontal)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMoreVertical = styled(MoreVertical)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMousePointer = styled(MousePointer)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMove = styled(Move)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoMusic = styled(Music)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoNavigation2 = styled(Navigation2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoNavigation = styled(Navigation)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoOctagon = styled(Octagon)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPackage = styled(Package)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPaperclip = styled(Paperclip)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPauseCircle = styled(PauseCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPause = styled(Pause)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPenTool = styled(PenTool)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPercent = styled(Percent)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhoneCall = styled(PhoneCall)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhoneForwarded = styled(PhoneForwarded)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhoneIncoming = styled(PhoneIncoming)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhoneMissed = styled(PhoneMissed)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhoneOff = styled(PhoneOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhoneOutgoing = styled(PhoneOutgoing)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPhone = styled(Phone)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPieChart = styled(PieChart)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPlayCircle = styled(PlayCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPlay = styled(Play)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPlusCircle = styled(PlusCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPlusSquare = styled(PlusSquare)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPlus = styled(Plus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPocket = styled(Pocket)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPower = styled(Power)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoPrinter = styled(Printer)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRadio = styled(Radio)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRefreshCcw = styled(RefreshCcw)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRefreshCw = styled(RefreshCw)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRepeat = styled(Repeat)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRewind = styled(Rewind)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRotateCcw = styled(RotateCcw)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRotateCw = styled(RotateCw)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoRss = styled(Rss)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSave = styled(Save)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoScissors = styled(Scissors)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSearch = styled(Search)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSend = styled(Send)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoServer = styled(Server)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSettings = styled(Settings)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShare2 = styled(Share2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShare = styled(Share)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShieldOff = styled(ShieldOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShield = styled(Shield)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShoppingBag = styled(ShoppingBag)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShoppingCart = styled(ShoppingCart)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoShuffle = styled(Shuffle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSidebar = styled(Sidebar)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSkipBack = styled(SkipBack)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSkipForward = styled(SkipForward)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSlack = styled(Slack)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSlash = styled(Slash)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSliders = styled(Sliders)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSmartphone = styled(Smartphone)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSmile = styled(Smile)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSpeaker = styled(Speaker)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSquare = styled(Square)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoStar = styled(Star)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoStopCircle = styled(StopCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSun = styled(Sun)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSunrise = styled(Sunrise)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoSunset = styled(Sunset)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTable = styled(Table)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTablet = styled(Tablet)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTag = styled(Tag)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTarget = styled(Target)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTerminal = styled(Terminal)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoThermometer = styled(Thermometer)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoThumbsDown = styled(ThumbsDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoThumbsUp = styled(ThumbsUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoToggleLeft = styled(ToggleLeft)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoToggleRight = styled(ToggleRight)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTool = styled(Tool)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTrash2 = styled(Trash2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTrash = styled(Trash)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTrello = styled(Trello)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTrendingDown = styled(TrendingDown)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTrendingUp = styled(TrendingUp)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTriangle = styled(Triangle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTruck = styled(Truck)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTv = styled(Tv)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTwitch = styled(Twitch)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoTwitter = styled(Twitter)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoType = styled(Type)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUmbrella = styled(Umbrella)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUnderline = styled(Underline)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUnlock = styled(Unlock)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUploadCloud = styled(UploadCloud)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUpload = styled(Upload)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUserCheck = styled(UserCheck)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUserMinus = styled(UserMinus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUserPlus = styled(UserPlus)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUserX = styled(UserX)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUser = styled(User)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoUsers = styled(Users)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVideoOff = styled(VideoOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVideo = styled(Video)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVoicemail = styled(Voicemail)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVolume1 = styled(Volume1)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVolume2 = styled(Volume2)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVolumeX = styled(VolumeX)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoVolume = styled(Volume)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoWatch = styled(Watch)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoWifiOff = styled(WifiOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoWifi = styled(Wifi)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoWind = styled(Wind)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoXCircle = styled(XCircle)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoXOctagon = styled(XOctagon)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoXSquare = styled(XSquare)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoX = styled(X)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoYoutube = styled(Youtube)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoZapOff = styled(ZapOff)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoZap = styled(Zap)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoZoomIn = styled(ZoomIn)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

export const IcoZoomOut = styled(ZoomOut)<IconProps>`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  margin: ${(props) => props.margin || '1rem'};
`;

