import React, { useEffect, useState } from "react";
import { IcoClipboard } from "llane-ui";
import {
  CodeBlockHeader,
  CodeBlockTitle,
  CodeBlockWrapper,
  CopyToClipboardButton,
  ProcessedCode,
  ProcessedCodeWrapper
} from "../theme";
import { useTranslate } from "../contexts/SettingContext";

interface CodeBlockProps {
  code: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code }) => {
  const { t } = useTranslate();
  const [copyButtonText, setCopyButtonText] = useState("LabelCopyCode");

  useEffect(() => {
    setCopyButtonText("LabelCopyCode");
  }, []);

  /* code processor to run checks and actions before the final code is issued to the user */
  const processCode = (inputCode: string): string => {
    /* this check supresses the first character if it is a blank space */
    if (inputCode.startsWith(" ")) {
      return inputCode.substring(1);
    }
    return inputCode;
  };

  const processedCode = processCode(code);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(processedCode).then(
      () => {
        setCopyButtonText("LabelCopiedToClipboard");
        setTimeout(() => {
          setCopyButtonText("LabelCopyCode");
        }, 2000);
      },
      () => {
        setCopyButtonText("LabelFailedToCopy");
        setTimeout(() => {
          setCopyButtonText("LabelCopyCode");
        }, 2000);
      }
    );
  };

  return (
    <CodeBlockWrapper>
      <CodeBlockHeader>
        <CodeBlockTitle>{t("LabelCodeBlock")}</CodeBlockTitle>
        <CopyToClipboardButton onClick={copyToClipboard}>
          <IcoClipboard size="12px" margin="auto 5px -1px auto" />{" "}
          <span>{t(copyButtonText)}</span>
        </CopyToClipboardButton>
      </CodeBlockHeader>
      <ProcessedCodeWrapper>
        <ProcessedCode>{processedCode}</ProcessedCode>
      </ProcessedCodeWrapper>
    </CodeBlockWrapper>
  );
};

export default CodeBlock;
