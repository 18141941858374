import { createGlobalStyle } from "styled-components";
import { BorderColor } from "./Colors";

// General
export const BorderRadius = `50px`;
export const DefaultGap = `1rem`;
export const BorderThickness = `1px`;
export const DefaultBoxShadow = `0 2px 8px rgba(0, 0, 0, 0.1)`;

// App Navigation Bar
export const AppNavBarHeight = `72px`;
export const AppNavBarBackdropFilter = `blur(10px)`;
export const AppNavBarBoxShadow = `0 2px 8px rgba(0, 0, 0, 0.1)`;
export const AppNavBarBorderRadius = `0`;
export const AppNavBarMargin = `0`;
export const AppNavBarPadding = `0`;
export const AppNavBarFloatingMargin = `1rem`;
export const AppNavBarFloatingBorderRadius = `10px`;
export const AppNavBarSectionPadding = `0`;
export const AppNavBarMobileBreakingPoint = `769px`;
export const AppNavBarDefaultPosition = `bottom`;

// App Wrapper
export const AppWrapperAlignHorizontal = `center`;
export const AppWrapperAlignVertical = `top`;
export const AppWrapperMargin = `0`;
export const AppWrapperPadding = `0`;
export const AppWrapperOverflow = `visible`;
export const AppWrapperDefaultWidth = `100%`;

// Box
export const BoxPadding = `20px`;
export const BoxBorderRadius = `30px`;

// Button
export const ButtonPadding = `10px 15px`;
export const ButtonFontWeight = `500`;
export const ButtonCursor = `pointer`;
export const ButtonOutlineBorder = `1px`;
export const ButtonLinkFontSize = `0.8rem`;
export const ButtonLinkPadding = `12px 17px`;

// Divider
export const DividerLineThickness = `1px`;

// Dropdown
export const DropdownPadding = `10px 40px`;
export const DropdownFontSize = `0.9rem`;
export const DropdownContainerPadding = `0`;
export const DropdownButtonBorderRadius = BorderRadius;
export const DropdownContainerBorderRadius = `20px`;
export const DropdownLinkPadding = `15px 20px`;

// Footer
export const FooterHeight = `auto`;
export const FooterBackdropFilter = `blur(10px)`;
export const FooterBoxShadow = `0 2px 8px rgba(0, 0, 0, 0.1)`;
export const FooterBorderRadius = `0`;
export const FooterMargin = `0`;
export const FooterPadding = `50px 1rem`;
export const FooterFloatingMargin = `1rem`;
export const FooterFloatingBorderRadius = `10px`;
export const FooterSectionPadding = `auto`;
export const FooterMobileBreakingPoint = `769px`;

// Hamburger Menu
export const HamburgerMenuLinkPadding = `20px`;
export const HamburgerMenuContainerPadding = `20px 0`;
export const HamburgerMenuContainerBorderRadius = `10px`;
export const HamburgerMenuContainerBorderThickness = `0px`;
export const HamburgerMenuContainerMinWidth = `200px`;
export const HamburgerMenuBoxShadow = `0 2px 8px rgba(0, 0, 0, 0.1)`;
export const HamburgerMenuDropdownFontSize = `0.9rem`;

// Header
export const HeaderHeight = `64px`;
export const HeaderBackdropFilter = `blur(10px)`;
export const HeaderBoxShadow = `0 2px 8px rgba(0, 0, 0, 0.1)`;
export const HeaderBorderRadius = `0`;
export const HeaderMargin = `0`;
export const HeaderPadding = `0`;
export const HeaderFloatingMargin = `1rem`;
export const HeaderFloatingBorderRadius = `10px`;
export const HeaderSectionPadding = `0 1rem`;
export const HeaderMobileBreakingPoint = `769px`;

// Input
export const InputPadding = `10px 15px`;
export const InputFontSize = `0.9rem`;

// Page Wrapper
export const PageWrapperMargin = `0`;
export const PageWrapperPadding = `-0`;
export const PageWrapperOverflow = "auto";
export const PageWrapperDefaultWidth = `1320px`;

// Submenu
export const SubmenuPadding = `1rem`;
export const SubmenuFontSize = `1rem`;
export const SubmenuContainerPadding = `20px 0`;
export const SubmenuContainerBorderRadius = `30px`;
export const SubmenuLinkPadding = `12px 15px`;

// Text Area
export const TextAreaBorderRadius = `30px`;
export const TextAreaPadding = `20px`;
export const TextAreaFontSize = `1rem`;

export const GlobalStyles = createGlobalStyle`
  * {
    scrollbar-width: thin;
    scrollbar-color: ${BorderColor} transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${BorderColor};
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.01);
        border-radius: 6px;
    }
  }
`;
