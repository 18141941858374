import styled, { keyframes } from "styled-components";
import { AppBackground, BorderColor, FlexContainer, TextColor } from "llane-ui";
import {
  BubbleBackgroundUser,
  BubbleBackgroundAi,
  PrimaryFontColor,
  AccentColorPressed,
  DefaultBorderRadius,
  PrimaryColorDimmed,
  DarkGreyColor,
  AccentTransparentBlack
} from "./default";

type StyledProps = {
  subject: "User" | "AI";
};

interface LoaderProps {
  delay: string;
}

// -- Animations ---> START

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
`;

// -- Animations ---> END

// -- General Components ---> START

export const Spinner = styled.div`
  border: 4px solid ${AccentColorPressed};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-left-color: ${PrimaryFontColor};
  animation: ${spin} 1s linear infinite;
`;

export const Dot = styled.div<LoaderProps>`
  background-color: ${TextColor};
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 2px;
  animation: ${bounce} 1.4s infinite ease-in-out;
  animation-delay: ${(props) => props.delay};
`;

export const DotsLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 5px auto;
`;

export const LoaderPaddingWrapper = styled.div`
  margin: auto;
  padding: 2rem;
`;

// -- General Components ---> END

// -- Prompt Components ---> START

export const PromptButton = styled.button`
  border-radius: 50%;
  background: transparent;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  margin: auto 10px;
  cursor: pointer;
  border: 1px solid ${TextColor};
  color: ${TextColor};
  padding: 5px;
  &:hover {
    background: ${AccentTransparentBlack};
  }
`;

export const FormattedResponseContainer = styled.div<StyledProps>`
  display: flex;
  justify-content: ${({ subject }) =>
    subject === "User" ? "flex-end" : "flex-start"};
  padding: 7px 0;
`;

export const FormattedResponse = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;

export const MessageBubble = styled.div<StyledProps>`
  max-width: 90%;
  background-color: ${({ subject }) =>
    subject === "User" ? `${BubbleBackgroundUser}` : `${BubbleBackgroundAi}`};
  color: ${({ subject }) =>
    subject === "User" ? `${PrimaryFontColor}` : `${TextColor}`};
  padding: 1.2rem 1rem;
  border-radius: ${({ subject }) =>
    subject === "User" ? `20px 20px 0 20px` : `0px 20px 20px 20px`};
  margin: 2px 0;
  ${({ subject }) =>
    subject === "User" ? "margin-right: 0;" : "margin-left: 0;"}
  word-wrap: break-word;
  text-align: left;
  animation: ${fadeUp} 0.3s ease-out 0s;
`;

export const ProcessingJsxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  min-height: 40px;
  padding: 1rem;
  scrollbar-width: thin;
  scrollbar-color: ${BorderColor} ${AppBackground};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5274e;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ff0000;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.025);
    border-radius: 6px;
  }
`;

// -- Prompt Components ---> END

// -- CodeBlock ---> START

export const CodeBlockWrapper = styled.div`
  margin: 1rem 0 0 0;
  padding: 0;
  border-radius: ${DefaultBorderRadius};
  background: ${DarkGreyColor};
`;

export const CodeBlockHeader = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  alignitems: center;
  background: ${PrimaryColorDimmed};
  border-radius: ${DefaultBorderRadius} ${DefaultBorderRadius} 0 0;
`;

export const CodeBlockTitle = styled.span`
  margin: 0;
`;

export const CopyToClipboardButton = styled.button`
  border: none;
  color: ${PrimaryFontColor};
  cursor: pointer;
  font-size: 10pt;
  background: transparent;
`;

export const ProcessedCodeWrapper = styled.div`
  background: ${DarkGreyColor};
  border-radius: 0 0 ${DefaultBorderRadius} ${DefaultBorderRadius};
  padding: 1rem;
  white-space: pre-wrap;
`;

export const ProcessedCode = styled.code`
  color: ${PrimaryFontColor};
  padding: 1rem;
  margin: 0;
  display: block;
  text-align: left;
`;

export const ResponsiveHeadContainer = styled(FlexContainer)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
// -- CodeBlock ---> END
