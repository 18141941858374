import { sendMessageToAi } from "../api/aiApiCall";
import { ChatMessage } from "../types/prompt";

const createImage = async (
  argument: string,
  accessToken: string,
  signal?: AbortSignal
) => {
  const payload = {
    content: argument,
    context: []
  };
  const response = await sendMessageToAi(
    "ArcadiaImage",
    payload,
    accessToken,
    signal
  );
  const data: ChatMessage = {
    subject: "AI",
    content: response,
    type: "photo"
  };
  return data;
};

export const defaultCommands = [{ command: "image", callback: createImage }];
