import React, {
  useState,
  ReactNode,
  ReactElement,
  cloneElement,
  Children,
  useRef
} from "react";
import styled from "styled-components";
import {
  DropdownLinkHoverBackground,
  TextColor,
  Text,
  IcoChevronDown,
  Sp,
  Span,
  BorderColor,
  AppBackground
} from "..";
import {
  BorderThickness,
  DefaultBoxShadow,
  DropdownButtonBorderRadius,
  DropdownContainerBorderRadius,
  DropdownContainerPadding,
  DropdownFontSize,
  DropdownLinkPadding,
  DropdownPadding
} from "../BaseThemeStyle";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

interface ComponentProps {
  color?: string;
  colorHover?: string;
  size?: string;
  weight?: string;
  decoration?: string;
  decorationHover?: string;
  background?: string;
  backgroundHover?: string;
  padding?: string;
  borderRadius?: string;
  borderThickness?: string;
  borderColor?: string;
  minWidth?: string;
  boxShadow?: string;
  isOpen?: boolean;
  mobileBreakingPoint?: string;
  title?: string;
  fontColor?: string;
  fontSize?: string;
  margin?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  position?: "left" | "right";
}

export const DropdownLink = styled.a<ComponentProps>`
  display: block;
  padding: ${(props) => props.padding || DropdownLinkPadding};
  color: ${(props) => props.color || TextColor};
  text-decoration: none;
  width: 100%;
  min-width: 75px;
  cursor: pointer;
  white-space: nowrap;
  z-index: 1001;
  &:hover {
    background-color: ${(props) =>
      props.backgroundHover || DropdownLinkHoverBackground};
  }
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;
`;

const DropdownContainer = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${AppBackground};
  padding: ${(props) => props.padding || DropdownContainerPadding};
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  width: ${(props) => props.width || "fit-content"};
  z-index: 1000;
  max-height: ${(props) => props.maxHeight || "500px"};
`;

const DropdownContentWrapper = styled.div<ComponentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + 5px);
  border-radius: ${DropdownContainerBorderRadius};
  border: ${BorderThickness} solid transparent;
  box-shadow: ${DefaultBoxShadow};
  overflow: hidden;
  box-sizing: border-box;
  width: "fit-content";
  height: "fit-content";
  z-index: 1000;
  left: ${(props) => (props.position === "left" ? 0 : "inherit")};
  right: ${(props) => (props.position === "right" ? 0 : "inherit")};
  backdrop-filter: blur(0px);
`;

const DropdownToggle = styled.div<ComponentProps>`
  background: transparent;
  border: ${BorderThickness} solid ${BorderColor};
  border-radius: ${DropdownButtonBorderRadius};
  color: ${TextColor};
  font-size: ${DropdownFontSize};
  padding: ${DropdownPadding};
  cursor: pointer;
  text-align: center;
  width: ${(props) => props.width || "fit-content"};
  box-sizing: border-box;
  white-space: nowrap;
`;

interface DropdownProps extends ComponentProps {
  children: ReactNode | ReactNode[];
  title?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  title = "Dropdown Title",
  maxHeight,
  width,
  position = "left"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();

  useOnClickOutside(node, () => {
    setIsOpen(false);
  });

  const cloneWithClickHandler = (child: ReactElement) => {
    const originalOnClick = child.props.onClick;

    const handleChildClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      originalOnClick?.(event); // Safely call the original onClick if it exists
      setIsOpen(false);
    };

    return cloneElement(child, { onClick: handleChildClick });
  };

  const enhancedChildren = Children.map(children, (child) =>
    React.isValidElement(child) ? cloneWithClickHandler(child) : child
  );

  return (
    <DropdownWrapper ref={node as any}>
      <DropdownToggle onClick={() => setIsOpen(!isOpen)}>
        <Text>
          {title}
          <Sp />
          <Span>
            <IcoChevronDown size="0.9rem" margin="auto auto -3px auto" />
          </Span>
        </Text>
      </DropdownToggle>
      {isOpen && (
        <DropdownContentWrapper position={position}>
          <DropdownContainer maxHeight={maxHeight} width={width}>
            {enhancedChildren}
          </DropdownContainer>
        </DropdownContentWrapper>
      )}
    </DropdownWrapper>
  );
};
