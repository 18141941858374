import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

interface ModalData {
  newPrompt: boolean;
  openPrompt: boolean;
  savePrompt: boolean;
  updatePrompt: boolean;
  summary: boolean;
  loading: boolean;
}

interface ModalContextType {
  state: ModalData;
  setState: React.Dispatch<React.SetStateAction<ModalData>>;
}

export const ModalContext = createContext<ModalContextType>({
  state: {
    newPrompt: false,
    openPrompt: false,
    savePrompt: false,
    updatePrompt: false,
    summary: false,
    loading: false
  },
  setState: () => {}
});

export const useModal = (key: string, locked = false) => {
  const { state, setState } = useContext(ModalContext);

  const isOpen = useMemo(() => {
    if (state && state[key as keyof ModalData]) {
      return true;
    } else {
      return false;
    }
  }, [state, key]);

  const toggleModal = useCallback(() => {
    if (locked) return;
    setState((prev: ModalData) => {
      if (prev && prev[key as keyof ModalData] !== undefined)
        return {
          ...prev,
          [key as keyof ModalData]: !prev[key as keyof ModalData]
        };
      else return prev;
    });
  }, [setState, key, locked]);

  const handleOpen = useCallback(() => {
    if (locked) return;
    setState((prev: ModalData) => {
      if (prev && prev[key as keyof ModalData] !== undefined)
        return {
          ...prev,
          [key as keyof ModalData]: true
        };
      else return prev;
    });
  }, [setState, key, locked]);

  const handleClose = useCallback(() => {
    if (locked) return;
    setState((prev: ModalData) => {
      if (prev && prev[key as keyof ModalData])
        return {
          ...prev,
          [key as keyof ModalData]: false
        };
      else return prev;
    });
  }, [setState, key, locked]);

  return { isOpen, toggleModal, handleClose, handleOpen };
};

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<ModalData>({
    newPrompt: false,
    openPrompt: false,
    savePrompt: false,
    updatePrompt: false,
    summary: false,
    loading: false
  });

  return (
    <ModalContext.Provider value={{ state, setState }}>
      {children}
    </ModalContext.Provider>
  );
};
