export const FineTuningSelections = {
  AI_Working_Sector: [
    "General",
    "Managerial",
    "Human Resources",
    "Information Technology",
    "Management",
    "Operations",
    "Sales",
    "Support"
  ],
  Specialization: [
    "General",
    "Coding & Programming",
    "Sales & Marketing",
    "Technical Support"
  ],
  Behavior: {
    Response_Style: [
      "Balanced",
      "Formal",
      "Informal",
      "Educational",
      "Professional"
    ],
    Preferred_Response_Length: [
      "Balanced",
      "Long and Comprehensive",
      "Short and Concise",
      "Extra Short"
    ],
    Base_Personality: ["Balanced", "Creative", "Formal"]
  }
};

/* NEED TO FIX - USING TRANSLATION

import { useTranslate } from "../contexts/SettingContext";

const { t } = useTranslate();

export const FineTuningSelections = {
  AI_Working_Sector: [
    `${t("LabelGeneral")}`,
    `${t("LabelManagement")}`,
    `${t("LabelHumanResources")}`,
    `${t("LabelInformationTechnology")}`,
    `${t("LabelOperations")}`,
    `${t("LabelSales")}`,
    `${t("LabelSupport")}`,
  ],
  Specialization: [
    `${t("LabelGeneral")}`,
    `${t("LabelCodingProgramming")}`,
    `${t("LabelMarketing")}`,
    `${t("LabelTechnicalSupport")}`,
  ],
  Behavior: {
    Response_Style: [
      `${t("LabelBalanced")}`,
      `${t("LabelFormal")}`,
      `${t("LabelInformal")}`,
      `${t("LabelEducational")}`,
      `${t("LabelProfessional")}`,
    ],
    Preferred_Response_Length: [
      `${t("LabelBalanced")}`,
      `${t("LabelLong")}`,
      `${t("LabelShort")}`,
      `${t("LabelExtraShort")}`,
    ],
    Base_Personality: [
      `${t("LabelBalanced")}`,
      `${t("LabelCreative")}`,
      `${t("LabelFormal")}`,
    ]
  }
};

*/
