import { getApiCall, postApiCall } from ".";

export const fetchSetting = (user_id: number, token = "") => {
  const url = `/setting/user/${user_id}`;
  return getApiCall(url, token).catch((err: any) => {
    if (err?.message === "Not Found") {
      return null;
    } else {
      throw new Error(err);
    }
  });
};

export const updateSetting = (payload: any, token = "") => {
  const url = `/setting/edit`;
  return postApiCall(url, payload, token);
};
