import { RouterProvider } from "react-router-dom";
import router from "./router";
import { UserProvider } from "./contexts/UserContext";
import { PromptProvider } from "./contexts/PromptContext";
import { ModalProvider } from "./contexts/ModalContext";
import { SettingProvider } from "./contexts/SettingContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <SettingProvider>
          <PromptProvider>
            <RouterProvider router={router} />
            <ToastContainer />
          </PromptProvider>
        </SettingProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
