export const BackendEndpoint = "https://backend.arcadia.neptureai.com/api";
export const ImageStorage = "https://backend.arcadia.neptureai.com/images";
export const ContextFactor = `10`;
export const FallbackAIEngineEndpoint = `https://api.neptureai.com/api/prompt/gpt35t`;

export const AIEngineEndpoints = {
  Arcadia: {
    Name: "Arcadia",
    extra: true
  },
  Zephyr: {
    Name: "Zephyr",
    extra: false
  },
  GPT35: {
    Name: "Chat-GPT",
    extra: false
  }
};

export const AIImageGenerationEndpoints = {
  ArcadiaImage: {
    Name: "Arcadia Image Generator"
  }
};

export const Languages = [
  {
    label: "English (EN/UK)",
    value: "en-uk"
  },
  {
    label: "العربية (AR)",
    value: "ar"
  },
  {
    label: "中文 (ZH/CN)",
    value: "zh-cn"
  },
  {
    label: "Deutsch (DE)",
    value: "de"
  },
  {
    label: "Español (ES)",
    value: "es"
  },
  {
    label: "Français (FR)",
    value: "fr"
  },
  {
    label: "हिंदी (HI)",
    value: "hi"
  },
  {
    label: "Italian (IT)",
    value: "it"
  },
  {
    label: "Português (PT/BR)",
    value: "pt-br"
  },
  {
    label: "Русский (RU)",
    value: "ru"
  }
];

export const themes = [
  {
    label: "LabelDark",
    value: "dark"
  },
  {
    label: "LabelLight",
    value: "light"
  }
];

export const CommandFailSentence = `Sorry but I could not find any command with the specified parameters. You may want to try a different command.`;

export const SelectList = [
  "Default",
  "Date, Newest First",
  "Date, Oldest First",
  "Alphabetical, A-Z",
  "Alphabetical, Z-A"
];
